<template>
  <div>
    <div class="contain">
      <div class="header">
        <div class="crumbs">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">
              <span class="header_title">首页&nbsp;</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item
              class="header_title"
              :to="{ path: '/cloudGoods' }"
            >
              <span class="header_title">&nbsp;云仓商品&nbsp;</span>
            </el-breadcrumb-item>
            <!-- <el-breadcrumb-item
              class="header_title"
              :to="{ path: '/goodsList' }"
            >
              <span class="header_title">&nbsp;全部商品&nbsp;</span>
            </el-breadcrumb-item> -->
            <el-breadcrumb-item
              class="header_title"
              :to="{ path: '/goodsList' }"
            >
              <span class="header_title"
                >&nbsp;{{ detailData.title }}&nbsp;</span
              >
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="detail_box">
        <div class="swiper">
          <div class="banner">
            <img
              v-real-img="mainImgUrl || bannerImgs[0]"
              src="../../assets/default_404.svg"
              style="width: 100%; height: 100%"
            />
          </div>
          <div class="bottom">
            <i class="el-icon-arrow-left left_icon" @click="imgLeft()"></i>
            <i class="el-icon-arrow-right right_icon" @click="imgRight()"></i>
            <div class="Img_div">
              <div
                v-for="(item, index) in bannerImgs"
                :key="index"
                class="Img_li"
                :style="imgStyle"
                @click="changeImg(item, index)"
              >
                <img
                  :class="index === imgActiveIndex ? 'img_activeBorder' : ''"
                  v-real-img="item"
                  src="../../assets/default_404.svg"
                  style="width: 60px; height: 60px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="right_box">
          <div class="title">{{ detailData.title }}</div>
          <div class="label">
            <div class="item_label">{{ detailData.label_name }}</div>
          </div>
          <div class="ensure">
            平台保障：&nbsp;<img
              v-real-img="baseUrl + 'detail_icon1.png'"
              src="../../assets/default_404.svg"
              alt=""
            />&nbsp;全程在线服务&nbsp;&nbsp;<img
              v-real-img="baseUrl + 'detail_icon2.png'"
              src="../../assets/default_404.svg"
              alt=""
            />&nbsp;资金担保&nbsp;&nbsp;<img
              v-real-img="baseUrl + 'detail_icon3.png'"
              src="../../assets/default_404.svg"
              alt=""
            />&nbsp;一站式服务
          </div>
          <div class="line"></div>
          <div class="specs">
            商品规格：&nbsp;
            <span v-for="(item, index) in specsData" :key="index">{{
              item.specifications
            }}</span>
          </div>
          <div class="line"></div>
          <div class="sales">
            销量：&nbsp;{{ detailData.web_show_sale_num || 0 }}
          </div>
          <div class="sales">
            咨询量：&nbsp;{{ detailData.web_show_consult_num || 0 }}&nbsp;次
          </div>
        </div>
      </div>
      <div class="bold_line"></div>
      <div class="rich_text">
        <div class="intro" v-if="detailData.detail_text">
          {{ detailData.detail_text }}
        </div>
        <img
          v-for="(item, index) in detail_img"
          v-real-img="item"
          src="../../assets/default_404.svg"
          :key="index"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
import Product from "@/api/product";
export default {
  data() {
    return {
      baseUrl: config.baseUrl,
      page: 1,
      pageSize: 20,
      productId: undefined,
      detailData: {},
      mainImgUrl: "",
      bannerImgs: [],
      imgActiveIndex: 0, // 当前移动图片的索引值
      imgDistance: 0, // 移动的距离
      allDistance: 0, // 总移动距离
      specsData: [], //规格数组
      detail_img: [], //详情图
      areaInfo: JSON.parse(sessionStorage.getItem("areaInfo")),
    };
  },
  created() {
    this.getParams();
    this.loadData();
  },
  mounted() {},
  computed: {
    imgStyle() {
      return {
        transform: `translate3d(${this.imgDistance}px, 0, 0)`, // 计算移动的距离(x,y,z)
      };
    },
  },
  methods: {
    // 获取上一个页面传过来的参数
    getParams() {
      this.productId = this.$route.query.id;
    },
    // 初始化
    loadData() {
      let city_code = this.areaInfo.area[0].slice(0, -2);
      let params = {
        id: this.productId,
        city_code,
      };
      Product.getProductdetail(params).then((res) => {
        if (res.code != 200) {
          this.$message({
            message: "商品不存在",
          });
          setTimeout(() => {
            this.$router.back();
          }, 1000);
          return;
        }
        this.detailData = res.data;
        this.bannerImgs = res.data.images;
        this.specsData = res.data.specs_data;
        this.detail_img = res.data.detail_img;
      });
    },
    changeImg(item, index) {
      this.mainImgUrl = item;
      this.imgActiveIndex = index;
    },
    imgLeft() {
      if (this.imgActiveIndex > 0) {
        this.imgActiveIndex--; // 索引值-1
        this.detailData.images.forEach((item, index) => {
          // 循环小图,通过判断索引值赋值给大图
          if (this.imgActiveIndex === index) {
            this.mainImgUrl = item;
          }
        });
      }
      if (this.imgDistance < 0) {
        var index = 0;
        const temp = window.setInterval(() => {
          // 利用定时器实现图片左右移动的动画效果
          if (index < 34) {
            // 移动次数(33次)
            this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
            index++;
            return;
          } else {
            window.clearInterval(temp); // 移动完清除定时器
          }
        }, 10);
      }
    },
    imgRight() {
      if (this.imgActiveIndex < this.detailData.images.length - 1) {
        this.imgActiveIndex++;
        this.detailData.images.forEach((item, index) => {
          if (this.imgActiveIndex === index) {
            this.mainImgUrl = item;
          }
        });
        if (this.imgActiveIndex >= 5) {
          this.allDistance = -68 * (this.imgActiveIndex - 4);
          var index = 0;
          const temp = window.setInterval(() => {
            if (index < 34) {
              this.imgDistance -= 2; // 每次向右移动的距离
              index++;
              return;
            } else {
              window.clearInterval(temp);
            }
          }, 10);
        }
      } else if (this.imgActiveIndex === this.detailData.images.length - 1) {
        // 到达最后一张图片，再点击跳转回第一张
        this.imgActiveIndex = 0;
        this.mainImgUrl = this.detailData.images[0];
        var index = 0;
        const temp = window.setInterval(() => {
          // 利用定时器实现图片左右移动的动画效果
          if (index < Math.abs(this.allDistance / 2)) {
            // 取绝对值再除
            this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
            index++;
            return;
          } else {
            window.clearInterval(temp); // 移动完清除定时器
          }
        }, 1);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.contain {
  background: #fff;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  .header {
    width: 100%;
    height: 44px;
    background: #f4f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    .header_title {
      font-size: 18px;
      transition: all 100ms linear;
      &:hover {
        color: #0042cb !important;
      }
    }
    .crumbs {
      width: 1300px;
      margin: 0 auto;
    }
  }
  .detail_box {
    width: 1300px;
    // height: 516px;
    margin: 40px auto;
    display: flex;
    .swiper {
      width: 400px;
      height: 477px;
      .banner {
        width: 400px;
        height: 400px;
      }
      .bottom {
        position: relative;
        height: 60px;
        margin-top: 16px;
        .right_icon,
        .left_icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: 24px;
          font-weight: 700;
          z-index: 99;
          cursor: pointer;
        }
        .left_icon {
          left: 6px;
        }
        .right_icon {
          right: 6px;
        }
        .Img_div {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          width: 340px;
          height: 60px;
          overflow: hidden;
          list-style: none;
          .Img_li {
            width: 60px;
            height: 60px;
            margin: 0 4px;
            cursor: pointer;
          }
        }
        .img_activeBorder {
          border: 2px solid #0042cb;
          box-sizing: border-box;
        }
      }
    }
    .right_box {
      width: 960px;
      margin-left: 40px;
      padding-top: 32px;
      box-sizing: border-box;
      .title {
        width: 960px;
        font-size: 20px;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .label {
        display: flex;
        margin-top: 26px;
        .item_label {
          height: 20px;
          font-size: 14px;
          color: #0042cb;
          line-height: 20px;
          text-decoration: underline;
          margin-right: 20px;
        }
      }
      .ensure {
        margin-top: 26px;
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
      }
      .line {
        margin-top: 26px;
        width: 960px;
        height: 1px;
        background: #f4f8ff;
      }
      .specs {
        margin-top: 26px;
        font-size: 14px;
        line-height: 20px;
        span {
          color: #0042cb;
          margin-right: 20px;
        }
      }
      .sales {
        margin-top: 26px;
        height: 20px;
        line-height: 20px;
      }
    }
  }
  .bold_line {
    width: 100%;
    height: 44px;
    background: #f4f8ff;
  }
  .rich_text {
    margin: 40px auto 0;
    width: 1300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .intro {
      width: 1300px;
      margin: 0 auto;
      font-size: 22px;
      line-height: 40px;
      text-align: justify;
      margin-bottom: 10px;
      padding: 0 50px;
      box-sizing: border-box;
      text-align: center;
    }

    img {
      width: 1300px;
      margin: 0 auto;
      height: auto;
      border: 1px solid #ebebeb;
      box-sizing: border-box;
    }
  }
}
</style>