<template>
  <div>
    <div class="contain">
      <div class="banner">
        <div class="previous" @click="bannerPrev">
          <img
            v-real-img="baseUrl + 'left_icon.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <div class="next" @click="bannerNext">
          <img
            v-real-img="baseUrl + 'right_icon.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <el-carousel
          :interval="5000"
          height="633px"
          arrow="never"
          trigger="click"
          ref="carousel"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img
              v-real-img="item.url"
              src="../../assets/default_404.svg"
              class="banner_img"
              alt=""
              @click="toLink(item)"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="topView">
        <div class="topTitle">
          <div class="itemTitle" @click="titleClick(1)">
            <div
              class="name"
              :style="clicked ? 'color:#191919;' : 'color:#bebfc0;'"
            >
              销量
            </div>
            <img
              v-real-img="
                baseUrl + (clicked ? 'top_black.png' : 'top_gray.png')
              "
              src="../../assets/default_404.svg"
              alt=""
            />
          </div>
          <span style="color: #0042cb; font-size: 22px"
            >&nbsp;&nbsp;/&nbsp;&nbsp;</span
          >
          <div class="itemTitle" @click="titleClick(2)">
            <div
              class="name"
              :style="!clicked ? 'color:#191919;' : 'color:#bebfc0;'"
            >
              咨询
            </div>
            <img
              v-real-img="
                baseUrl + (!clicked ? 'top_black.png' : 'top_gray.png')
              "
              src="../../assets/default_404.svg"
              alt=""
            />
          </div>
        </div>
        <div class="topGoods">
          <div class="previous" @click="goodsPrev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="next" @click="goodsNext">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-carousel
            :interval="5000"
            height="400px"
            arrow="never"
            trigger="click"
            ref="goodsCarousel"
          >
            <el-carousel-item>
              <div class="swiperBox">
                <div
                  class="item"
                  v-for="(item, index) in rankList"
                  :key="index"
                  v-if="index < 5"
                  @click="toDetail(item)"
                >
                  <img
                    v-real-img="item.cover_image"
                    src="../../assets/default_404.svg"
                    class="goods_img"
                    alt=""
                  />
                  <div class="title">
                    {{ item.title }}
                  </div>
                  <div class="rank">
                    <img
                      v-real-img="baseUrl + 'top_blue.png'"
                      src="../../assets/default_404.svg"
                      alt=""
                    />
                    <span>.{{ index + 1 }}</span>
                  </div>
                  <div class="sales" v-if="clicked">
                    销量：{{ item.web_show_sale_num }}
                  </div>
                  <div class="sales" v-else>
                    咨询量：{{ item.web_show_consult_num }}次
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="swiperBox">
                <div
                  class="item"
                  v-for="(item, index) in rankList"
                  :key="index"
                  v-if="index >= 5"
                  @click="toDetail(item)"
                >
                  <img
                    v-real-img="item.cover_image"
                    src="../../assets/default_404.svg"
                    class="goods_img"
                    alt=""
                  />
                  <div class="title">
                    {{ item.title }}
                  </div>
                  <div class="rank">
                    <img
                      v-real-img="baseUrl + 'top_blue.png'"
                      src="../../assets/default_404.svg"
                      alt=""
                    />
                    <span>.{{ index + 1 }}</span>
                  </div>
                  <div class="sales" v-if="clicked">
                    销量：{{ item.web_show_sale_num }}
                  </div>
                  <div class="sales" v-else>
                    咨询量：{{ item.web_show_consult_num }}次
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="classify">
        <div
          class="item"
          v-for="(item, index) in typeList"
          :key="index"
          @click="toGoodsList(item)"
        >
          <img
            v-real-img="item.icon"
            src="../../assets/default_404.svg"
            class="icon"
            alt=""
          />
          <div class="name">{{ item.title }}</div>
        </div>
      </div>
      <div class="goodsList">
        <div class="topTitle">推荐商品</div>
        <div class="content">
          <div
            class="item"
            v-for="(item, index) in productList"
            :key="index"
            @click="toDetail(item)"
          >
            <img
              v-real-img="item.cover_image"
              src="../../assets/default_404.svg"
              class="goods_img"
              alt=""
            />
            <div class="title">
              {{ item.title }}
            </div>
            <div class="sales">销量：{{ item.web_show_sale_num }}</div>
            <div class="consult">咨询量：{{ item.web_show_consult_num }}次</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
import Product from "@/api/product";
export default {
  data() {
    return {
      baseUrl: config.baseUrl,
      clicked: true,
      saleList: [], // 销量排行
      consultList: [], // 咨询排行
      typeList: [
        {
          id: "",
          title: "全部分类",
          icon: "https://file.vipcs.com/qslwebsite/all_type_icon.png",
          children: [
            {
              id: "",
              title: "全部分类",
              icon: "https://file.vipcs.com/qslwebsite/all_type_icon.png",
            },
          ],
        },
      ], // 分类列表
      productList: [], // 商城首页商品列表
      rankList: [],
      loading: false,
      bannerList: [],
      areaInfo: JSON.parse(sessionStorage.getItem("areaInfo")),
    };
  },
  created() {
    this.loadBanner();
    this.getTypeList();
    this.loadData();
  },
  mounted() {},
  methods: {
    // 获取banner
    async loadBanner() {
      let default_banner = [
        {
          url: "https://file.vipcs.com/qslwebsite/shop_banner_1.png",
          link: "",
        },
        {
          url: "https://file.vipcs.com/qslwebsite/shop_banner_2.png",
          link: "",
        },
      ];
      let res = await Product.getBannerList();
      if (res.code != 200) return;
      if (res.data.banner_pc_list.images) {
        if (res.data.banner_pc_list.images.length > 0) {
          this.bannerList = res.data.banner_pc_list.images;
        } else {
          this.bannerList = default_banner;
        }
      } else {
        this.bannerList = default_banner;
      }
    },
    // 获取分类
    async getTypeList() {
      let city_code = this.areaInfo.area[0].slice(0, -2);
      let res = await Product.getTypeList({ city_code });
      if (res.code != 200) return;
      this.typeList = [...this.typeList, ...res.data.types_list];
    },
    // 初始化
    loadData() {
      let city_code = this.areaInfo.area[0].slice(0, -2);
      Product.getHomeData({
        city_code,
      }).then((res) => {
        this.saleList = res.data.sale_top_list;
        this.rankList = res.data.sale_top_list;
        this.consultList = res.data.consult_top_list;
        this.productList = res.data.indexshow_list;
      });
    },
    // 商品列表跳转
    toGoodsList(row) {
      this.$router.push({
        name: "goodsList",
        params: { id: row.id },
      });
    },
    // banner上一张
    bannerPrev() {
      this.$refs.carousel.prev();
    },
    goodsPrev() {
      this.$refs.goodsCarousel.prev();
    },
    // banner下一张
    bannerNext() {
      this.$refs.carousel.next();
    },
    goodsNext() {
      this.$refs.goodsCarousel.next();
    },
    // top榜切换
    titleClick(type) {
      if (type == 1) {
        this.clicked = true;
        this.rankList = this.saleList;
      }
      if (type == 2) {
        this.clicked = false;
        this.rankList = this.consultList;
      }
    },
    // 商品详情
    toDetail(row) {
      this.$router.push({
        path: "goodsDetail",
        query: { id: row.id },
      });
    },
    // 轮播图跳转
    toLink(row) {
      this.$router.push({
        path: row.link,
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.contain {
  background: #fff;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  .banner {
    width: 100%;
    height: 633px;
    position: relative;
    .banner_img {
      width: 100%;
      height: 633px;
      background: #d0e2ff;
    }
    .previous,
    .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      color: #fff;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .previous {
      left: 14.3%;
    }
    .next {
      right: 14.3%;
    }
  }
  .topView {
    .topTitle {
      margin: 23px auto 0;
      padding-left: 22px;
      box-sizing: border-box;
      width: 1300px;
      height: 34px;
      border-bottom: 1px solid #0042cb;
      display: flex;
      align-items: baseline;
      position: relative;
      &::after {
        content: "";
        width: 4px;
        height: 27px;
        background: #0042cb;
        position: absolute;
        left: 10px;
      }
      .itemTitle {
        height: 28px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .name {
          height: 25px;
          font-size: 18px;
          color: #191919;
          line-height: 25px;
          margin-right: 2px;
        }
      }
    }
    .topGoods {
      margin: 23px auto 0;
      width: 1300px;
      height: 400px;
      position: relative;
      .previous,
      .next {
        position: absolute;
        font-size: 60px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        color: #999;
        cursor: pointer;
      }
      .previous {
        left: -45px;
      }
      .next {
        right: -45px;
      }
      .swiperBox {
        margin: 0 auto;
        width: 1300px;
        height: 400px;
        display: flex;
        justify-content: space-between;
        .item {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 243px;
          height: 380px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 4px;
          background: #fafafa;
          .goods_img {
            width: 223px;
            height: 223px;
            border-radius: 2px;
          }
          .title {
            height: 50px;
            margin-top: 10px;
            width: 223px;
            font-size: 16px;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          .rank {
            height: 56px;
            font-size: 26px;
            font-weight: 600;
            color: #0042cb;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .sales {
            height: 25px;
            font-size: 18px;
            font-weight: 400;
            color: #0042cb;
            line-height: 25px;
          }
        }
      }
    }
  }
  .classify {
    margin: 60px auto 0;
    width: 1304px;
    margin-top: 60px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 210px;
      height: 162px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .icon {
        border-radius: 2px;
        width: 80px;
        height: 80px;
      }
      .name {
        font-size: 18px;
        line-height: 37px;
        margin-top: 5px;
        text-align: center;
      }
    }
  }
  .goodsList {
    margin: 0 auto;
    width: 1300px;
    height: auto;
    .topTitle {
      margin-top: 20px;
      padding-left: 22px;
      box-sizing: border-box;
      width: 1300px;
      height: 34px;
      line-height: 34px;
      border-bottom: 1px solid #0042cb;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      position: relative;
      &::after {
        content: "";
        width: 4px;
        height: 27px;
        background: #0042cb;
        position: absolute;
        left: 10px;
      }
    }
    .content {
      width: 1320px;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .item {
        float: left;
        display: flex;
        flex-direction: column;
        width: 243px;
        height: 380px;
        padding: 10px;
        margin-bottom: 20px;
        margin-right: 21px;
        box-sizing: border-box;
        border-radius: 4px;
        background: #fafafa;
        cursor: pointer;
        transition: all 100ms linear;
        &:hover {
          box-shadow: 0px 0px 10px 0px #a9b1bd;
        }

        .goods_img {
          width: 223px;
          height: 223px;
          border-radius: 2px;
        }
        .title {
          height: 50px;
          margin-top: 10px;
          width: 223px;
          font-size: 18px;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .consult {
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          margin-top: 5px;
          color: #666;
        }
        .sales {
          margin-top: 20px;
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          color: #666;
        }
      }
    }
  }
}
</style>