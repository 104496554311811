import http from "@/request/http.js";
/**
 * 请求示例
 * @param method 请求方式 get post put delete
 * @param url 请求地址
 * http.method(url,params)
 */
class Cooperation {
  // 提交加盟申请
  static addJoinAsk(params) {
    return http.post('website/addJoinAsk', params)
  }
  // 提交服务商加盟申请
  static addJoinService(params) {
    return http.post('website/addServiceJoinAsk', params)
  }

}
export default Cooperation
