<template>
  <div>
    <el-backtop :right="40" :bottom="60" :visibility-height="200">
      <div class="backTop">
        <i class="el-icon-upload2"></i>
      </div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: "backTop",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.backTop {
  color: #0042cb;
}
</style>