<template>
  <div id="app">
    <navigation />
    <router-view v-if="isRouterAlive" />
    <backTop></backTop>
    <div class="propagate">
      <propagateTips :scrollTop="scrollTop"></propagateTips>
    </div>
  </div>
</template>

<script>
import navigation from "@/components/navMenu/index";
import backTop from "@/components/backTop";
import propagateTips from "@/components/propagateTips";
export default {
  name: "App",
  components: {
    backTop,
    navigation,
    propagateTips,
  },
  provide() {
    // 父组件中通过provide来提供变量，在子组件中通过inject来注入变量
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      scrollTop: 0,
      isRouterAlive: true, //控制视图是否显示的变量
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取导航图距顶距离
    handleScroll() {
      let scrollTop = Math.floor(
        document.documentElement.scrollTop || document.body.scrollTop
      );
      this.scrollTop = scrollTop;
    },
    // 页面刷新
    reload() {
      this.isRouterAlive = false; // 先关闭，
      this.$nextTick(function () {
        document.documentElement.scrollTop = 0;
        this.isRouterAlive = true; // 再打开
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  color: #191919;
  font-family: PingFangSC-Regular, PingFang SC;
  .propagate {
    position: fixed;
    z-index: 99;
    bottom: 135px;
    right: 10px;
  }
  img {
    opacity: 0;
    transition: all 150ms linear;
  }
  .clarity {
    opacity: 1;
  }
}
@media screen and (min-width: 1300px) {
  #app {
    overflow-x: hidden;
  }
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #aaa;
}
</style>
