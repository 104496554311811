<template>
  <div>
    <div class="contain">
      <img class="clarity" src="../../assets/404.png" alt="" />
      <div class="text">
        倒计时{{ count }}S后<span @click="toHome()">返回首页</span>...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: undefined,
      count: 5,
    };
  },
  created() {},
  mounted() {
    this.countdown();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 倒计时返回首页
    countdown() {
      this.timer = setInterval(() => {
        // 创建定时器
        if (this.count === 0) {
          // 关闭定时器
          clearInterval(this.timer);
          this.timer = null;
          this.count = 5;
          this.$router.push("/");
        } else {
          this.count--;
        }
      }, 1000);
    },
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang='scss' scoped>
.contain {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  // position: absolute;
  // top: 0;
  background: #fff;
  // z-index: 1000;
  .text {
    margin-top: 20px;
    font-size: 26px;
    letter-spacing: 3px;
    color: #96befa;
    span {
      cursor: pointer;
      border-bottom: 2px solid #96befa;
    }
  }
}
</style>