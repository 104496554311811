// 配置路由相关的信息
import VueRouter from 'vue-router'
import Vue from 'vue'
import routes from './routerData'
Vue.use(VueRouter)
//捕获调用push切换到同一路由时报错的异常。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//捕获调用replace切换到同一路由时报错的异常。
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes,
  mode: 'hash',
  // 每次刷新路由都回到顶部
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next('/NotFound') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
  }
  next() // 如果匹配到正确跳转
})
export default router
