<template>
  <div>
    <!-- <div class="propagate" v-if="scrollTop > 600"> -->
    <div class="propagate" v-if="propagateShow && clickClose">
      <img
        v-real-img="baseUrl + 'propagate_code.png'"
        src="../../assets/default_404.svg"
        alt=""
      />
      <i class="el-icon-circle-close close" @click="close"></i>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
export default {
  name: "propagateTips",
  props: {
    scrollTop: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      baseUrl: config.baseUrl,
      propagateShow: false,
      clickClose: true,
    };
  },
  watch: {
    scrollTop: {
      handler(newVal, oldVal) {
        this.propagateShow = newVal > 600 ? true : false;
      },
      immediate: true,
    },
    $route: "getPath",
  },
  created() {},
  mounted() {},
  methods: {
    // 关闭悬浮框
    close() {
      this.propagateShow = false;
      this.clickClose = false;
    },
    // 监听路由改变
    getPath() {
      this.clickClose = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.propagate {
  width: 170px;
  position: relative;
  img {
    width: 170px;
    height: auto;
  }
  .close {
    position: absolute;
    font-size: 28px;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    color: #02318f;
    opacity: 0.3;
  }
}
</style>