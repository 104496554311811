<template>
  <div>
    <div class="contain">
      <div class="banner">
        <img v-real-img="baseUrl + 'boss_long.png'" src="../../assets/default_404.svg" alt="" />
      </div>
      <div class="Introduction">
        <img
          v-real-img="baseUrl + 'logo_opacity.png'"
          src="../../assets/default_404.svg"
          class="background"
          alt=""
        />
        <div class="title">企商链简介</div>
        <div class="content">
          <img
            class="logo"
            v-real-img="baseUrl + 'qsl_logo_new.png'"
            src="../../assets/default_404.svg"
            alt=""
            style="float: right; margin-left: 10px"
          />
          <div class="text-indent">
            企商链——为满足代账公司市场营销、人资管理双重降本增效而生，为打造财税企服高价值赋能。企商链是一款帮助财税企业会员制运营+云仓供应链增收落地的私域运营工具，能打造完整的用户标签体系，帮助代账机构做好客户的企业全生命周期终身价值挖掘，通过运营指导做好客户分层、分级筛选分析，制定针对性营销策略及企服产品匹配，从而实现降本增效，向存量要增收，向多元企服要高价值！
          </div>
          <div class="text-indent">
            针对财税行业客户开发能力不足，企业服务价值不高，客户难以留存等痛点问题，企商链整合中小企业多元企服业务需求，为代账企业打造一站式企服业务线上私域
            “云店”，通过企服+企管双通道为打造财税企服高价值赋能；搭建线上谈单新场景，帮助财税企业降低获客成本，完善在线业务链条，打造强势品牌形象，让对接更精准，签单更容易，赚钱更轻松。在此基础上，企商链更打通了企服产业互联网上下游渠道，通过构建企服资源生态，紧密团结全国代账机构，赋能中小微企业，补足企服
            “最后一公里” 缺失链条，致力于提升企业全生命周期服务价值，提高创业成功率。
          </div>
          <div class="text-indent">
            目前，企商链科技已构建起以北京总公司为渠道资源中心，沈阳、济南、郑州三地分公司为支点的企业服务网络，正加紧步伐，布局全国市场。
          </div>
          <div class="text-indent">
            2023年3月，企商链科技与开源证券就新三板挂牌北交所IPO相关事项完成合作签约，正式开启资本之路！
          </div>
        </div>
      </div>
      <div class="kernel">
        <img
          v-real-img="baseUrl + 'logo_opacity.png'"
          src="../../assets/default_404.svg"
          class="background"
          alt=""
        />
        <div class="title">企商链精神内核</div>
        <div class="content">文化是一个国家、一个民族的灵魂，是一个国家、一个民族的精神家园。</div>
        <div class="content">文化同样也是一个企业在激烈的市场竞争中屹立不倒的精神支柱。</div>
      </div>
      <div class="center_item">
        <div class="left_box">
          <div
            class="option"
            v-for="(item, index) in centerList"
            :key="index"
            @click="centerTap(index)"
            @mouseenter="mouseenter(index)"
            @mouseleave="mouseleave(index)"
          >
            <div :class="['name', index == centerIndex ? 'hover' : '']">
              {{ item.name }}
            </div>
            <img
              v-real-img="baseUrl + item.icon"
              src="../../assets/default_404.svg"
              class="background"
              alt=""
            />
          </div>
        </div>
        <div class="right">
          <transition name="el-fade-in">
            <div
              class="right_box"
              v-for="(item, index) in centerList"
              :key="index"
              v-if="centerIndex == index"
            >
              <div class="title">{{ item.title }}</div>
              <div class="title" v-if="centerIndex == 1">成为财税企服行业产业互联网业内标杆</div>
              <div class="content">{{ item.content }}</div>
              <div class="image">
                <img v-real-img="baseUrl + item.image" src="../../assets/default_404.svg" alt="" />
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="process_box">
        <div class="process">
          <div class="title">企商链成长历程</div>
          <div class="introduce">
            <div class="head">11年&nbsp;</div>
            财税行业洞察调研，深刻了解行业沿革、现状、趋势及企业痛点；
          </div>
          <div class="introduce">
            <div class="head">6年&nbsp;</div>
            互联网行业经验，通过互联网思维帮助财税企业多元增收；
          </div>
          <div class="introduce">
            <div class="head">4年&nbsp;</div>
            潜心研发迭代，孜孜不倦地打磨产品生命力、创造力、财富力！
          </div>
          <div class="years">
            <div
              v-for="(item, index) in yearsData"
              :key="index"
              :class="['item_year', yearIndex == index ? 'item_year_click' : '']"
              @mouseenter="yearClick(index)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="swiper">
            <i class="prev el-icon-arrow-left" @click="prev"></i>
            <i class="next el-icon-arrow-right" @click="next"></i>
            <el-carousel
              :interval="5000"
              arrow="never"
              indicator-position="none"
              ref="carousel"
              height="750px"
              @change="swiperChange"
            >
              <el-carousel-item v-for="(item, index) in yearsData" :key="index" name="index">
                <div class="swiper_item">
                  <div v-for="(child, key) in item.children" :key="key" class="item_box">
                    <div class="year_title">
                      {{ index == 5 || index == 6 ? item.name + "半年" : item.name + "年" }}
                    </div>
                    <div class="month">{{ child.title }}</div>
                    <div class="content">{{ child.content }}</div>
                    <div class="img"></div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="title_name">我们的服务信条</div>
        <div class="content">
          <div class="item">
            <div class="icon">
              <img
                v-real-img="baseUrl + 'funeng_icon.png'"
                src="../../assets/default_404.svg"
                alt=""
              />
            </div>
            <div class="right">
              <div class="title">为财税人赋能</div>
              <div class="text">
                我们拥有行业专业研发实力与资深运营团队，为您的企业多元化发展及企服需求科技赋能。
              </div>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img
                v-real-img="baseUrl + 'aixin_icon.png'"
                src="../../assets/default_404.svg"
                alt=""
              />
            </div>
            <div class="right">
              <div class="title">做有温度的服务</div>
              <div class="text">
                我们始终坚持有温度的服务理念，无论任何时候您遇到困难，我们都会及时提供宛如家人呵护一般的有效解决方案。
              </div>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img
                v-real-img="baseUrl + 'people_icon.png'"
                src="../../assets/default_404.svg"
                alt=""
              />
            </div>
            <div class="right">
              <div class="title">效率和责任</div>
              <div class="text">
                我们将每一位客户都视为重要的创业伙伴，我们坚持以最快效率帮助伙伴增收，以赋能代账机构及财税企业健康增长为己任。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="area">
        <div class="title">办公地点</div>
        <div class="content">
          <div class="item">
            <div class="image">
              <img
                v-real-img="baseUrl + 'beijing_ip.png'"
                src="../../assets/default_404.svg"
                alt=""
              />
            </div>
            <div class="name">北京总部</div>
            <div class="text">地址：北京市朝阳区建外街道众秀大厦1802室</div>
          </div>
          <div class="item">
            <div class="image">
              <img
                v-real-img="baseUrl + 'shenyang_ip.png'"
                src="../../assets/default_404.svg"
                alt=""
              />
            </div>
            <div class="name">沈阳</div>
            <div class="text">地址：沈阳市沈河区北站CBD财富中心</div>
          </div>
          <div class="item">
            <div class="image">
              <img
                v-real-img="baseUrl + 'zhengzhou_ip.png'"
                src="../../assets/default_404.svg"
                alt=""
              />
            </div>
            <div class="name">郑州</div>
            <div class="text">地址：河南自贸试验区郑州片区王鼎国际大厦</div>
          </div>
          <div class="item">
            <div class="image">
              <img
                v-real-img="baseUrl + 'jinan_ip.png'"
                src="../../assets/default_404.svg"
                alt=""
              />
            </div>
            <div class="name">济南</div>
            <div class="text">地址：济南市历下区新泺大街奥盛大厦</div>
          </div>
          <div class="item">
            <div class="image">
              <img
                v-real-img="baseUrl + 'area_guangzou.png'"
                src="../../assets/default_404.svg"
                alt=""
              />
            </div>
            <div class="name">广州</div>
            <div class="text">地址：广州市天河区天河路壬丰大厦</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
export default {
  data() {
    return {
      baseUrl: config.baseUrl,
      centerList: [
        {
          name: "企业使命",
          icon: "arrow_blue.png",
          title: "为打造财税企服高价值赋能",
          content:
            "4年前，企商链成立之初就立下宏愿：改变财税行业中无序竞争的现状，帮助每一家财税企业多元化发展。企商链有勇气、有决心、有毅力推动一场深刻的财税多元化革命。",
          image: "qiyeshiming.png",
          index: 0,
        },
        {
          name: "企业愿景",
          icon: "arrow_white.png",
          title: "打造企服生态供应链",
          content:
            "优秀的公司帮助客户，卓越的公司成就客户。企商链通过强大的整合能力建立起一条完整的企服生态供应链，发自内心的将客户需求放在第一位，这是对愿景的最好诠释，这是企商链成就客户的实际行动。",
          image: "qiyeyuanjing.png",
          index: 1,
        },
        {
          name: "企业价值观",
          icon: "arrow_white.png",
          title: "以奋斗者为本 为客户成功 做主人翁",
          content:
            "没有伞的孩子，必须努力奔跑。企商链愿意在客户成功的路上撑一把伞，因为每一个奋斗者都值得尊敬，企商链感谢每一个志同道合的伙伴，我们一起哭，一起笑，一起战斗！让我们以梦为马，不负韶华！",
          image: "qiyejiazhiguan.png",
          index: 2,
        },
      ],
      centerIndex: 0,
      yearsData: [
        {
          name: 2018,
          children: [
            {
              title: "11月",
              content:
                "企商链前身“VIP财税”项目组成立，12月初平台正式上线。作为能向财税公司提供精准意向客咨的资源平台，“VIP财税”凭借客咨精准，成单转化率高，迅速赢得全国市场，客户满意度极高，转介绍效果特别好，赢得了过硬口碑。",
            },
            {
              title: "12月中旬",
              content:
                "“VIP财税”仅用1个月时间便打通全国五大核心区域市场，在南京、武汉、济南、天津、杭州，收获了客户的广泛好评。",
            },
          ],
        },
        {
          name: 2019,
          children: [
            {
              title: "年初",
              content: "“VIP财税”发展城市合伙人模式，收获50+城市合伙人，合作至今。",
            },
          ],
        },
        {
          name: 2020,
          children: [
            {
              title: "2月",
              content:
                "产品模式转变升级“企商链”品牌正式脱胎诞生，由直接提供客咨转变为向代账机构提供获客及营销团队管理工具、企业服务产品、会员制运营系统的综合平台，功能更加全面，帮助代账机构对抗静默影响，夯实营销力。",
            },
            {
              title: "12月",
              content: "企商链团队赴海南参加首届全国财博会，引发轰动。",
            },
          ],
        },
        {
          name: 2021,
          children: [
            {
              title: "年初",
              content: "企商链2.0启动研发，企服云店云仓模式再升级。",
            },
            {
              title: "8月&11月",
              content:
                "企商链分别参展上海税博会、第二届海南财博会，场场爆单，企商链CEO洪峰在论坛发言，输出先进的代账机构营销及管理理念引发广泛关注和认同。",
            },
          ],
        },
        {
          name: 2022,
          children: [
            {
              title: "年初",
              content:
                "受静默影响，全国展会停摆，市场收缩，企商链逆势增长，赋能全国财税代账机构做好线上企业服务，维稳客户。企商链CEO洪峰与代友商学院、华财会计等行业头部连麦，分享利润增长成功经验。",
            },
          ],
        },
        {
          name: "2023上",
          children: [
            {
              title: "2月",
              content: "企商链成为华财会计联盟专业合伙人。",
            },
            {
              title: "3月",
              content:
                "全国市场迎来解封，代账行业盛会不断，企商链先在济南、郑州、北京连落三子，组建分公司，布局全国服务网络；随后团队马不停蹄参加首届代博会、第三届财博会等全国性大会，以全新升级的3.0AI客服系统，更加丰富的云仓企服产品、更加精美的云店展现形式惊艳代账圈，蝉联大会“最受欢迎”展位，签约火爆场面实至名归。会后，企商链科技与湖南省代理记账行业协会会长单位——湖南明信咨询集团签订战略合作协议。",
            },
          ],
        },
        {
          name: "2023下",
          children: [
            {
              title: "4月至7月",
              content:
                "企商链4.0升级研发会召开，研发部、运营部扩编，客户成功部组建几件大事陆续落地；同期，企商链与开源证券就新三板挂牌及北交所IPO相关事项完成合作签约；与国内税务信息化行业头部企业——北京旋极百旺科技有限公司签订深度合作协议，企商链平台实现电子发票开具功能；济南、郑州、沈阳分公司相继签下区域头部财税服务平台：山东百企慧、山东冠军齐鲁、河南企业帮、大连卓勤等名企；企商链CEO洪峰应各地代账行业协会邀请邀先后走访重庆、济南、长沙、北京进行巡回宣讲，先锋的增收理念让同行耳目一新。",
            },
            {
              title: "7月",
              content:
                "企商链进一步优化市场战略布局，全新模式推广合伙人火热招募，再燃全国赋能新高潮！",
            },
          ],
        },
      ],
      yearIndex: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    centerTap(key) {
      this.centerList.map((item, index) => {
        if (key == index) {
          item.icon = "arrow_blue.png";
        } else {
          item.icon = "arrow_white.png";
        }
      });
    },
    // 鼠标移入
    mouseenter(key) {
      this.centerIndex = key;
      this.centerList.map((item, index) => {
        if (key == index) {
          item.icon = "arrow_blue.png";
        } else {
          item.icon = "arrow_white.png";
        }
      });
    },
    // 鼠标移出
    mouseleave(key) {
      this.centerList.map((item, index) => {
        if (key == index) {
          item.icon = "arrow_blue.png";
        } else {
          item.icon = "arrow_white.png";
        }
      });
    },
    // 年份点击
    yearClick(key) {
      this.yearIndex = key;
      this.$refs.carousel.setActiveItem(key);
    },
    // 轮播图切换
    swiperChange(key) {
      this.yearIndex = key;
    },
    // 上一张
    prev() {
      this.$refs.carousel.prev();
    },
    // 下一张
    next() {
      this.$refs.carousel.next();
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  background: #f4f8ff;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  .banner {
    img {
      width: 100%;
      height: auto;
    }
  }
  .text-indent {
    text-indent: 2em;
  }
  .Introduction {
    width: 1300px;
    height: 770px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px #e3ecfb;
    border-radius: 20px;
    margin: 60px auto 0;
    position: relative;
    overflow: hidden;
    .background {
      width: 719px;
      height: 770px;
      position: absolute;
    }
    .title {
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #0042cb;
      line-height: 56px;
      margin: 70px 0 10px 78px;
      position: relative;
      z-index: 1;
    }
    .content {
      width: 1206px;
      position: relative;
      z-index: 1;
      font-size: 22px;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      margin: 0 auto;
      text-align: justify;
      .top {
        width: 890px;
      }
      .logo {
        margin-top: 10px;
        display: inline-block;
        width: 277px;
        height: 277px;
        border-radius: 11px;
      }
    }
    .bottom {
      margin: 0 auto;
      width: 1206px;
      margin-left: 78px;
      position: relative;
      z-index: 1;
      width: 1244px;
      height: 244px;
      font-size: 22px;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
    }
  }
  .kernel {
    width: 1300px;
    height: 304px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px #e3ecfb;
    border-radius: 20px;
    margin: 60px auto;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .background {
      width: 604px;
      position: absolute;
      z-index: -1;
      right: 0;
      top: -104px;
    }
    .title {
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #0042cb;
      line-height: 56px;
      margin-top: 64px;
      margin-bottom: 20px;
      text-align: center;
    }
    .content {
      width: 896px;
      font-size: 28px;
      font-weight: 400;
      color: #333333;
      line-height: 51px;
      text-align: center;
      margin: 0 auto;
    }
  }
  .center_item {
    width: 1300px;
    margin: 0 auto;
    height: 680px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px #e3ecfb;
    border-radius: 12px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    .left_box {
      width: 530px;
      margin-right: 76px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .option {
        height: 270px;
        position: relative;
        overflow: hidden;
        .background {
          width: 530px;
          position: absolute;
          z-index: 0;
          left: -10px;
          top: -10px;
        }
        .name {
          width: 240px;
          text-align: center;
          position: relative;
          z-index: 5;
          height: 56px;
          font-size: 38px;
          font-weight: 600;
          color: #666666;
          line-height: 56px;
          margin: 74px 0 0 120px;
          &::after {
            content: "";
            position: absolute;
            width: 200px;
            height: 34px;
            background: #f4f8ff;
            z-index: -1;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .hover {
          color: #fff;
          &::after {
            content: "";
            position: absolute;
            width: 200px;
            height: 34px;
            background: #ff6600;
            z-index: -1;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .right {
      width: 630px;
      height: 100%;
      overflow: hidden;
    }
    .right_box {
      width: 630px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        width: 630px;
        font-size: 36px;
        font-weight: 600;
        color: #0042cb;
        line-height: 56px;
      }
      .content {
        margin-top: 10px;
        width: 630px;
        height: 160px;
        font-size: 26px;
        font-weight: 400;
        color: #191919;
        line-height: 40px;
        text-align: justify;
      }
      .image {
        margin-top: 40px;
        width: 630px;
        height: 260px;
        image {
          width: 630px;
          height: 260px;
        }
      }
    }
  }
  .process_box {
    width: 100%;
    height: 1420px;
    background: #ffffff;
  }
  .process {
    width: 1300px;
    margin: 0 auto;
    height: 100%;
    margin-top: 60px;
    overflow: hidden;
    box-sizing: border-box;
    .title {
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #0042cb;
      line-height: 56px;
      margin: 60px auto 0;
      text-align: center;
      position: relative;
      z-index: 1;
      &::after {
        content: "";
        width: 360px;
        height: 36px;
        background: #f4f8ff;
        position: absolute;
        z-index: -1;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .introduce {
      width: 100%;
      margin: 28px auto 0;
      display: flex;
      align-items: baseline;
      justify-content: center;
      font-size: 28px;
      font-weight: 400;
      color: #191919;
      line-height: 80px;
      .head {
        font-size: 40px;
        font-weight: 600;
        color: #0042cb;
      }
    }
    .years {
      margin: 60px auto 0;
      width: 1300px;
      padding: 0 50px;
      height: 92px;
      display: flex;
      justify-content: center;
      border-bottom: 2px solid #a3c1ff;
      box-sizing: border-box;
      .item_year {
        flex: 1;
        text-align: center;
        font-size: 40px;
        color: #a3c1ff;
        line-height: 56px;
        position: relative;
        transition: all 100ms ease;
        cursor: pointer;
        &::after {
          content: "";
          width: 14px;
          height: 14px;
          border-radius: 50%;
          position: absolute;
          background: #a3c1ff;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .item_year_click {
        color: #0042cb;
        font-weight: bold;
        &::after {
          content: "";
          width: 28px;
          height: 28px;
          background: #0042cb;
          border-radius: 50%;
          position: absolute;
          bottom: -15px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .swiper {
      width: 1280px;
      margin: 74px auto 0;
      position: relative;
      // background: #a3c1ff;
      .swiper_item {
        width: 1080px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        margin: 0 auto;
        .item_box {
          width: 480px;
          display: flex;
          flex-direction: column;
          .year_title {
            height: 56px;
            font-size: 36px;
            font-weight: 400;
            color: #191919;
            line-height: 56px;
            position: relative;
            z-index: 2;
            &::after {
              content: "";
              width: 40px;
              height: 7px;
              background: #0042cb;
              position: absolute;
              bottom: -7px;
              left: 0;
            }
          }
          .month {
            height: 40px;
            font-size: 24px;
            font-weight: 600;
            color: #191919;
            line-height: 40px;
            margin-top: 17px;
          }
          .content {
            width: 480px;
            height: 600px;
            font-size: 22px;
            font-weight: 400;
            color: #191919;
            line-height: 200%;
            text-align: justify;
            overflow: hidden; //超出的文本隐藏
            display: -webkit-box;
            -webkit-line-clamp: 13; // 超出多少行
            -webkit-box-orient: vertical;
          }
        }
      }
      .prev {
        position: absolute;
        top: 140px;
        left: 0;
        font-size: 40px;
        z-index: 10;
        cursor: pointer;
      }

      .next {
        position: absolute;
        top: 140px;
        font-size: 40px;
        right: 0;
        z-index: 10;
        cursor: pointer;
      }
    }
  }
  .footer {
    width: 100%;
    height: 390px;
    overflow: hidden;
    .title_name {
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #0042cb;
      line-height: 56px;
      margin: 60px 0 0 0;
      text-align: center;
      position: relative;
      z-index: 2;
      &::after {
        content: "";
        width: 360px;
        height: 36px;
        background: #fff;
        position: absolute;
        z-index: -1;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .content {
      width: 1300px;
      margin: 64px auto 0;
      display: flex;
      justify-content: space-between;

      .item {
        width: 420px;
        display: flex;
        .icon {
          width: 75px;
          height: 75px;
          margin-right: 4px;
        }
        .right {
          .title {
            margin-top: 4px;
            height: 30px;
            font-size: 20px;
            font-weight: 600;
            color: #191919;
            line-height: 30px;
          }
          .text {
            width: 338px;
            text-align: justify;
            margin-top: 4px;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 32px;
          }
        }
      }
    }
  }
  .area {
    height: 622px;
    width: 100%;
    overflow: hidden;
    background: #fff;
    .title {
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #0042cb;
      line-height: 56px;
      margin: 60px 0 0 0;
      text-align: center;
      position: relative;
      z-index: 2;
      &::after {
        content: "";
        width: 268px;
        height: 36px;
        background: #f4f8ff;
        position: absolute;
        z-index: -1;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .content {
      width: 1300px;
      display: flex;
      justify-content: space-between;
      margin: 68px auto 0;
      .item {
        // width: 305px;
        margin: 0 5px;

        flex: 1;
        .image {
          width: 100%;
          height: 230px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          height: 28px;
          font-size: 20px;
          font-weight: 600;
          color: #191919;
          line-height: 28px;
          margin-top: 19px;
        }
        .text {
          text-align: justify;
          font-size: 18px;
          font-weight: 400;
          color: #191919;
          line-height: 25px;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
