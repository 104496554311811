<template>
  <div>
    <div class="contain">
      <div class="banner">
        <img
          v-real-img="baseUrl + 'goodsPrices.png'"
          src="../../assets/default_404.svg"
          alt=""
        />
      </div>
      <div class="table_box">
        <div class="table_header">
          <div>功能</div>
          <div>营销管理版</div>
          <div>运营指导版</div>
        </div>
        <el-table :data="tableList" style="width: 100%" :show-header="false">
          <el-table-column width="400" align="center">
            <template #default="{ row }">
              <div class="item_box">
                <div class="tatble_title">{{ row.title }}</div>
                <div class="tatble_intro">{{ row.intro }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="400" align="center">
            <template #default="{ row }">
              <div class="item_box">
                <div class="tatble_icon">
                  <img
                    v-real-img="baseUrl + row.icon_1"
                    src="../../assets/default_404.svg"
                    alt=""
                  />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="400" align="center">
            <template #default="{ row }">
              <div class="item_box">
                <div class="tatble_icon">
                  <img
                    v-real-img="baseUrl + row.icon_2"
                    src="../../assets/default_404.svg"
                    alt=""
                  />
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
export default {
  data() {
    return {
      baseUrl: config.baseUrl,
      tableList: [
        {
          title: "超级多元获客神器",
          intro: "应用获客、内容获客、问答获客、电商获客",
          icon_1: "tick_icon.png",
          icon_2: "tick_icon.png",
        },
        {
          title: "应用工具",
          intro: "调查问卷、公司核名、融投天下、商标查询、财税计算器",
          icon_1: "tick_icon.png",
          icon_2: "tick_icon.png",
        },
        {
          title: "店铺功能",
          intro: "展示、支付、产品上传、营销、分销等全功能",
          icon_1: "tick_icon.png",
          icon_2: "tick_icon.png",
        },
        {
          title: "云仓产品",
          intro: "一键上架云仓产品",
          icon_1: "tick_icon.png",
          icon_2: "tick_icon.png",
        },
        {
          title: "云店数据",
          intro: "产品数据、访客数据、交易数据等",
          icon_1: "tick_icon.png",
          icon_2: "tick_icon.png",
        },
        {
          title: "会员系统",
          intro: "会员信息、会员动态、会员列表",
          icon_1: "tick_icon.png",
          icon_2: "tick_icon.png",
        },
        {
          title: "会员权誉",
          intro: "会员权益配置、权益使用、积分商城",
          icon_1: "tick_icon.png",
          icon_2: "tick_icon.png",
        },
        {
          title: "会员管理",
          intro: "会员分级分组管理、客服系统、团队指标",
          icon_1: "tick_icon.png",
          icon_2: "tick_icon.png",
        },
        {
          title: "账户资产",
          intro: "账户总资产、云仓商品分润、资产收支明细等",
          icon_1: "tick_icon.png",
          icon_2: "tick_icon.png",
        },
        {
          title: "售后服务",
          intro: "店铺使用教学、一对一教会使用",
          icon_1: "tick_icon.png",
          icon_2: "tick_icon.png",
        },
        {
          title: "定期运营指导",
          intro: "私域指导、店铺指导、运营指导等",
          icon_1: "error_icon.png",
          icon_2: "tick_icon.png",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.contain {
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .table_box {
    width: 1200px;
    box-shadow: 0px 0px 15px 6px rgba(226, 238, 255, 0.5);
    border-radius: 28px;
    border: 2px solid #0042cb;
    margin: 60px 0 90px;
    overflow: hidden;
    padding-bottom: 25px;
    .item_box {
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .tatble_icon {
        width: 20px;
        height: 20px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .tatble_title {
        font-size: 18px;
        font-weight: 600;
        color: #191919;
      }
      .tatble_intro {
        font-size: 14px;
        font-weight: 400;
        color: #666;
      }
    }
    .table_header {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f5f7fa;
      div {
        flex: 1;
        text-align: center;
        height: 56px;
        font-size: 32px;
        font-weight: 600;
        color: #0042cb;
        line-height: 56px;
      }
    }
  }
  .el-table::v-deep {
    border: 0;
    th,
    tr,
    td {
      border: 0;
      background-color: #fff;
    }
    &::before {
      height: 0px;
    }
    &::after {
      width: 0;
    }
    .el-table__fixed:before {
      height: 0;
    }
  }
  .el-table::v-deep th.is-leaf {
    /* 去除上边框 */
    border: none;
  }
}
</style>