<template>
  <div>
    <div class="contain">
      <div class="banner">
        <div class="previous" @click="bannerPrev">
          <img
            v-real-img="baseUrl + 'left_icon.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <div class="next" @click="bannerNext">
          <img
            v-real-img="baseUrl + 'right_icon.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <el-carousel
          :interval="5000"
          height="633px"
          arrow="never"
          trigger="click"
          ref="carousel"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img
              v-real-img="item.url"
              src="../../assets/default_404.svg"
              alt=""
              class="banner_img"
              @click="toLink(item)"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="center_box">
        <div class="center_title">
          企商链，让财税企服对接更精准！<br />签单更容易，赚钱更轻松！
        </div>
        <div class="center_bg">
          <transition name="el-zoom-in-center">
            <img
              alt=""
              v-for="(item, index) in centerList"
              :key="index"
              src="../../assets/default_404.svg"
              v-if="index == centerIndex"
              v-real-img="showImg"
            />
          </transition>
        </div>
        <div class="center_body">
          <div class="left_box">
            <div
              v-for="(item, index) in centerList"
              :class="['item', centerIndex == index ? 'selected' : '']"
              :key="index"
              v-if="index < 3"
              @mouseenter="mouseenter(index)"
            >
              <div class="title">{{ item.title }}</div>
              <div class="intro">{{ item.intro }}</div>
            </div>
          </div>
          <div class="right_box">
            <div
              v-for="(item, index) in centerList"
              :class="['item', centerIndex == index ? 'selected' : '']"
              :key="index"
              v-if="index >= 3"
              @mouseenter="mouseenter(index)"
            >
              <div class="title">{{ item.title }}</div>
              <div class="intro">{{ item.intro }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="item">
          <img
            v-real-img="baseUrl + 'content_bg1.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <div class="item">
          <img
            v-real-img="baseUrl + 'content_bg7.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <div class="item" style="background-color: #f6f9fe">
          <img
            v-real-img="baseUrl + 'content_bg6.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <div class="item">
          <img
            v-real-img="baseUrl + 'content_bg5_new.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <div class="item" style="background-color: #f6f9fe; margin-top: -2px">
          <img
            v-real-img="baseUrl + 'content_bg4.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <div class="item">
          <img
            v-real-img="baseUrl + 'content_bg3.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <div class="item" style="background-color: #f6f9fe">
          <img
            v-real-img="baseUrl + 'content_bg2.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
      </div>
      <div class="footer">
        <div class="title">战略合作伙伴</div>
        <div class="top">
          <img
            v-for="item in 3"
            v-real-img="
              'https://file.vipcs.com/qslwebsite/com_logo_' + item + '.png'
            "
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <div class="title">部分合作企业</div>
        <div class="tips">排名不分前后</div>
        <div class="bottom">
          <img
            v-for="item in 52"
            v-real-img="
              'https://file.vipcs.com/qslwebsite/clogo' + item + '.png'
            "
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
      </div>
      <div class="beian">
        企商链科技官网 Copyright {{ webLink }} &nbsp;&nbsp;| &nbsp;&nbsp;
        <span @click="toBeian()"> ©{{ ICP }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
export default {
  components: {},
  data() {
    return {
      baseUrl: config.baseUrl,
      showImg: "https://file.vipcs.com/qslwebsite/index_show1.png",
      centerList: [
        {
          title: "搭建谈单新场景 一站式企服私域“云店”",
          intro:
            "您可通过企商链“云店”将企服产品线上化，让业务菜单展示更为直观系统；借助分享链接洞察客户心理，无惧时间、空间总能即时沟通；一键转发，方便老客户帮您转介绍。",
          image: "https://file.vipcs.com/qslwebsite/index_show1.png",
        },
        {
          title: "企业品牌力强塑造 专业形象高客单",
          intro:
            "企业微官网与员工名片彰显企业实力，帮您向客户塑造良好的第一印象，强劲赋能品牌营销力，助您轻松赢得信任促成成交。",
          image: "https://file.vipcs.com/qslwebsite/index_show2.png",
        },
        {
          title: "营销+管理双赋能 全员营销多获客",
          intro:
            "“获客雷达”功能，分享丰富内容即可破译客户静默浏览动作背后的成交“密码”，根据浏览轨迹判断其业务需求点，助力全员精准营销。",
          image: "https://file.vipcs.com/qslwebsite/index_show3.png",
        },
        {
          title: "企服+企管双通道 “云仓”增加盈利点",
          intro:
            "涵盖企服、企管课程及工具、高利润差异化服务产品等，全面满足客户企业需求，助代账公司增净润，轻松转型成以财税为核心的全能型企服平台。",
          image: "https://file.vipcs.com/qslwebsite/index_show4.png",
        },
        {
          title: "营销活动激活 会员制绑定忠诚客户",
          intro:
            "多样营销活动拿来即用，续费拉新省心省力，把流量绑定为私域永久会员忠诚客户。还可上架代卖客户产品，帮客户赚钱，有差异化高附加值服务，客户再也“切不走”。",
          image: "https://file.vipcs.com/qslwebsite/index_show5.png",
        },
        {
          title: "订单管理 营销增收看得见",
          intro:
            "会员数据、员工数据、订单数据，后台看板全面体现，方便代账机构老板轻松管理，业绩所属清晰可查，全员增收，净润提升，长期增长尽在掌握！",
          image: "https://file.vipcs.com/qslwebsite/index_show6.png",
        },
      ],
      centerIndex: 0,
      bannerList: [
        {
          url: "https://file.vipcs.com/qslwebsite/index_banner_1.png",
          link: "serviceCase",
        },
        {
          url: "https://file.vipcs.com/qslwebsite/home_banner_3.png",
          link: "serviceCase",
        },
        {
          url: "https://file.vipcs.com/qslwebsite/home_banner_1.png",
          link: "serviceCase",
        },
        {
          url: "https://file.vipcs.com/qslwebsite/home_banner_2.png",
          link: "serviceCase",
        },
      ],
      ICP: process.env.VUE_APP_FILING_NUMBER,
      webLink: process.env.VUE_APP_LINK,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // banner上一张
    bannerPrev() {
      this.$refs.carousel.prev();
    },
    // banner下一张
    bannerNext() {
      this.$refs.carousel.next();
    },
    // 鼠标移入
    mouseenter(key) {
      this.centerIndex = key;
      this.showImg = this.centerList[key].image;
    },
    // 轮播图跳转
    toLink(row) {
      this.$router.push(row.link);
    },
    toBeian() {
      // https://beian.miit.gov.cn/
      window.open("https://beian.miit.gov.cn/");
    },
  },
};
</script>

<style lang='scss' scoped>
.contain {
  background: #fff;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  .banner {
    width: 100%;
    height: 633px;
    position: relative;
    .previous,
    .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      color: #fff;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .banner_img {
      width: 100%;
      height: 633px;
      background: #d0e2ff;
    }
    .previous {
      left: 14.3%;
    }
    .next {
      right: 14.3%;
    }
  }

  .center_box {
    width: 100%;
    height: 833px;
    position: relative;
    z-index: 1;
    background: #f6f9fe;
    overflow: hidden;
    .center_title {
      font-size: 32px;
      font-weight: 600;
      color: #0042cb;
      line-height: 58px;
      text-align: center;
      margin-top: 60px;
      position: relative;
      z-index: 1;
    }
    .center_bg {
      width: 1300px;
      height: 833px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
      // overflow: hidden;
      img {
        width: 1920px;
        height: 833px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    @media screen and (max-width: 850px) {
      .center_bg {
        left: -225px;
        transform: translateX(0);
      }
      .center_title {
        width: 850px;
        margin: 60px auto 0;
      }
    }
    .center_body {
      display: flex;
      position: relative;
      max-width: 1300px;
      min-width: 850px;
      align-items: center;
      justify-content: space-between;
      margin: 60px auto;
    }
    .left_box {
      height: 490px;
      width: 410px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .right_box {
      height: 490px;
      width: 410px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .item {
      width: 410px;
      height: 143px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.7);
      border: 1px solid #8fb9ff;
      padding: 17px 20px 0;
      box-sizing: border-box;
      transition: all 150ms linear;
      cursor: pointer;
      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        transition: all 150ms linear;
      }
      .intro {
        width: 364px;
        font-size: 14px;
        line-height: 25px;
        margin-top: 10px;
        transition: all 150ms linear;
      }
    }
    .selected {
      border: 1px solid #2f72fe;
      .title {
        color: #0042cb;
      }
      .intro {
        color: #2f72fe;
      }
    }
  }
  .content {
    width: 100%;
    .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      img {
        width: 1300px;
        margin: 0 auto;
      }
    }
  }
  .footer {
    width: 1300px;
    margin-top: 60px;
    margin: 60px auto 0;
    text-align: center;
    img {
      cursor: pointer;
      transition: all 170ms linear;
      &:hover {
        transform: translateY(-14px);
        // transform: scale(105%);
      }
    }
    .title {
      height: 45px;
      font-size: 32px;
      line-height: 45px;
    }
    .tips {
      height: 22px;
      font-size: 16px;
      line-height: 22px;
    }
    .top {
      margin: 20px auto 40px;
      width: 800px;
      display: flex;
      justify-content: space-between;
      img {
        width: 220px;
        height: 220px;
      }
    }
    .bottom {
      margin: 20px auto;
      img {
        width: 130px;
        height: 130px;
      }
    }
  }
  .beian {
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #333;
    color: #999;
    font-size: 12px;
    span {
      cursor: pointer;
    }
  }
}
</style>