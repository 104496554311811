import http from "@/request/http.js";
/**
 * 请求示例
 * @param method 请求方式 get post put delete
 * @param url 请求地址
 * http.method(url,params)
 */
class System {
  // 获取当前ip所在城市
  static getLocation(params) {
    return http.post('platform/website/getCityInfo', params)
  }
}
export default System

