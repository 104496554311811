<template>
  <div>
    <div style="height: 88px; width: 100%"></div>
    <div class="contain">
      <div class="nav">
        <div class="icon" @click="toHomePage">
          <img
            v-real-img="baseUrl + 'qsl_logo_long_new.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div>
        <div class="nav_bar">
          <div
            v-for="(item, index) in navList"
            :class="[
              'item',
              item.children ? 'active' : '',
              selectIndex == index ? 'selected' : '',
              item.title == '店铺登录' ? 'login' : '',
            ]"
            :key="index"
            @click="navTap(item, index)"
            @mouseenter="mouseenter(item)"
            @mouseleave="mouseleave(item)"
          >
            {{ item.title }}
            <i class="el-icon-arrow-down" v-if="item.children"></i>
            <i
              class="el-icon-caret-top triangle"
              v-if="selectShow && item.children"
            ></i>
            <transition name="el-fade-in-linear">
              <div class="son_list" v-if="selectShow && item.children">
                <div
                  class="son_item"
                  v-for="(child, child_index) in item.children"
                  :key="child_index"
                  @click.stop="itemClick(child)"
                >
                  {{ child.title }}
                </div>
              </div>
            </transition>
          </div>
        </div>
        <el-popover placement="bottom" v-model="cityShow" trigger="click">
          <div class="location" slot="reference">
            <i class="el-icon-location"></i>
            <span class="city">{{ areaName }}</span>
          </div>
          <area-picker
            ref="picker"
            v-model="area"
            :columns="2"
            @pick-city="handlePick"
          />
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
import areaPicker from "@/components/areaPicker/index"; // 文件对应路径
import System from "@/api/system";
export default {
  name: "navigation",
  components: { areaPicker },
  inject: ["reload"],
  data() {
    return {
      baseUrl: config.baseUrl,
      navList: [
        {
          title: "首页",
          index: 0,
          link: "home",
        },
        {
          title: "云仓商品",
          index: 1,
          link: "cloudGoods",
        },
        {
          title: "数据看板",
          index: 2,
          hyperlink: process.env.VUE_APP_BILINK,
          link: "",
        },
        // {
        //   title: "价格",
        //   index: 3,
        //   link: "",
        //   children: [
        //     {
        //       title: "产品价格",
        //       link: "goodsPrices",
        //       index: 0,
        //     },
        //     {
        //       title: "推广价格",
        //       link: "promotePrices",
        //       index: 1,
        //     },
        //   ],
        // },
        {
          title: "渠道合作",
          link: "cooperation",
        },
        {
          title: "服务案例",
          link: "serviceCase",
        },
        {
          title: "关于我们",
          link: "aboutUs",
        },
        {
          title: "店铺登录",
          link: "",
          hyperlink: "https://shop.vipcs.com",
        },
      ],
      selectShow: false,
      selectIndex: 0,
      area: [],
      areaName: "",
      cityShow: false,
    };
  },
  created() {
    this.getCity();
  },
  mounted() {
    this.getPath();
  },

  watch: {
    $route: "getPath",
  },
  methods: {
    // 地址选择
    handlePick(areaList) {
      this.areaName = [areaList].map((item) => item.name).join("");
      this.area = [areaList].map((item) => item.code);
      let areaInfo = {
        area: this.area,
        areaName: this.areaName,
      };
      sessionStorage.setItem("areaInfo", JSON.stringify(areaInfo));
      this.cityShow = false;
      this.reload();
    },
    // 获取当前ip地理位置
    getCity() {
      if (JSON.parse(sessionStorage.getItem("areaInfo"))) {
        let areaInfo = JSON.parse(sessionStorage.getItem("areaInfo"));
        this.areaName = areaInfo.areaName;
        this.area = areaInfo.area;
        return;
      }
      System.getLocation().then((res) => {
        if (res.code != 200) return;
        this.areaName = res.data.name;
        this.area = [(res.data?.provinceCode || "11") + "0000", res.data.code];
        let areaInfo = {
          area: this.area,
          areaName: this.areaName,
        };
        sessionStorage.setItem("areaInfo", JSON.stringify(areaInfo));
      });
    },
    // 监听路由改变
    getPath() {
      this.echo(this.$route.path);
    },
    echo(link) {
      this.navList.map((item, index) => {
        if (link === "/" + item.link) {
          this.selectIndex = index;
        }
        if (link === "/joinForm" && item.link === "cooperation") {
          this.selectIndex = index;
        }
        if (
          (link === "/goodsList" || link === "/goodsDetail") &&
          item.link === "cloudGoods"
        ) {
          this.selectIndex = index;
        }
        if (item.children) {
          item.children.map((row) => {
            if (link === "/" + row.link) {
              this.selectIndex = index;
            }
          });
        }
      });
    },
    // 导航栏点击
    navTap(row, index) {
      if (row.children) {
        return;
      }
      if (row.hyperlink) {
        window.open(row.hyperlink);
        return;
      }
      if (this.selectIndex == index) {
        this.$router.push(row.link);
        this.reload();
        return;
      }
      this.selectIndex = index;
      this.$router.push(row.link);
    },
    // 子项点击
    itemClick(row) {
      this.selectIndex = 3;
      this.$router.push(row.link);
    },
    // 鼠标移入
    mouseenter(item) {
      if (item.children) {
        this.selectShow = true;
      }
    },
    // 鼠标移出
    mouseleave(item) {
      if (item.children) {
        this.selectShow = false;
      }
    },
    // 回首页
    toHomePage() {
      document.documentElement.scrollTop = 0;
      this.$router.push("/");
      this.selectIndex = 0;
    },
  },
};
</script>

<style lang='scss' scoped>
.contain {
  width: 100%;
  height: 88px;
  box-sizing: border-box;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 150;
  .icon {
    width: 120px;
    height: 38px;
    margin-right: 4.5rem;
    cursor: pointer;
    img {
      width: 120px;
      height: 38px;
    }
  }
  .nav {
    max-width: 1300px;
    min-width: 810px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .location {
      white-space: nowrap;
      margin-left: 4.5rem;
      font-size: 20px;
      color: #191919;
      line-height: 29px;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #0042cb;
      }
    }
    .nav_bar {
      width: 100%;
      height: 88px;
      line-height: 88px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      font-weight: 400;
      .item {
        color: #000000;
        font-weight: normal;
        height: 88px;
        line-height: 88px;
        cursor: pointer;
      }
      .login {
        color: #2f72fe;
        text-decoration: underline;
      }
      .item:hover {
        color: #0042cb;
      }
      .active {
        position: relative;
      }
      .selected {
        color: #0042cb;
      }
      .triangle {
        font-size: 24px !important;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .son_list {
        width: 170px;
        height: 140px;
        background: #ffffff;
        position: absolute;
        top: 91px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .son_item {
          width: 88px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          color: #191919;
          margin: 10px;
        }
        .son_item:hover {
          color: #0042cb;
        }
      }
    }
  }
}
</style>
<style>
.el-popover {
  padding: 0 !important;
}
</style>