<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="contain">
      <div class="header">
        <div class="crumbs">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">
              <span class="header_title">首页&nbsp;</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item
              class="header_title"
              :to="{ path: '/cloudGoods' }"
            >
              <span class="header_title">&nbsp;云仓商品&nbsp;</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item
              class="header_title"
              :to="{ path: '/goodsList' }"
            >
              <span class="header_title">&nbsp;全部商品&nbsp;</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="search">
        <input
          v-model="keywords"
          @keyup.enter="submit"
          class="searchInput"
          placeholder="请输入内容"
        />
        <div class="reset" @click="reset">
          <i class="el-icon-circle-close"></i>
        </div>
        <div class="submit" @click="submit">
          <i class="el-icon-search"></i>
          搜索
        </div>
      </div>
      <div class="classify">
        <div :class="['row', opened ? 'opened' : '']">
          <div class="title">一级分类</div>
          <div
            v-for="(item, index) in typeList"
            :class="['item', item.selected ? 'selected' : '']"
            :key="index"
            @click="typeClick(item, index)"
          >
            {{ item.title }}
          </div>
          <div class="open" @click="openMore(1)">
            展开
            <i
              :class="[opened ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"
            ></i>
          </div>
        </div>
        <div
          :class="['row', childOpened ? 'opened' : '']"
          v-if="childTypeList.length > 0"
        >
          <div class="title">二级分类</div>
          <div
            v-for="(item, index) in childTypeList"
            :class="['item', item.selected ? 'selected' : '']"
            :key="index"
            @click="childTypeClick(item, index)"
          >
            {{ item.title }}
          </div>
          <div class="open" @click="openMore(2)">
            展开
            <i
              :class="[childOpened ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"
            ></i>
          </div>
        </div>
        <div class="row">
          <div class="title">排序</div>
          <div
            :class="['item', sort === '' ? 'selected' : '']"
            @click="sortClick('')"
          >
            综合 <i class="el-icon-arrow-down"></i>
          </div>
          <div
            :class="['item', sort === 1 ? 'selected' : '']"
            @click="sortClick(1)"
          >
            销量 <i class="el-icon-arrow-down"></i>
          </div>
          <div
            :class="['item', sort === 2 ? 'selected' : '']"
            @click="sortClick(2)"
          >
            咨询量 <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
      <div class="goodsList">
        <div class="content">
          <div
            class="item"
            v-for="(item, index) in productList"
            :key="index"
            @click="toDetail(item)"
          >
            <img
              class="goods_img"
              v-real-img="item.cover_image"
              src="../../assets/default_404.svg"
              alt=""
            />
            <div class="title">
              {{ item.title }}
            </div>
            <div class="sales">销量：{{ item.web_show_sale_num }}</div>
            <div class="consult">咨询量：{{ item.web_show_consult_num }}次</div>
          </div>
          <div class="footer">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              layout=" prev, pager, next, jumper"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
import Product from "@/api/product";
export default {
  data() {
    return {
      baseUrl: config.baseUrl,
      clicked: true,
      page: 1,
      pageSize: 40,
      typeList: [
        {
          id: "",
          title: "全部分类",
          icon: "https://file.vipcs.com/qslwebsite/all_type_icon.png",
          children: [
            {
              id: "",
              title: "全部分类",
              icon: "https://file.vipcs.com/qslwebsite/all_type_icon.png",
            },
          ],
        },
      ], // 分类列表
      childTypeList: [], // 二级分类列表
      productList: [], // 商品列表
      loading: false,
      opened: true,
      childOpened: false,
      firstId: undefined, // 一级分类id
      typeId: "", //请求typeid
      sort: undefined,
      currentPage: 1,
      totalCount: 0, //总条数
      selectedIndex: 0,
      keywords: "",
      areaInfo: JSON.parse(sessionStorage.getItem("areaInfo")),
    };
  },
  created() {
    this.getParams();
    this.getTypeList();
    this.loadData();
  },
  mounted() {},
  methods: {
    // 搜索
    submit() {
      this.loadData();
    },
    // 重置搜索
    reset() {
      this.keywords = "";
      this.loadData();
    },
    // 获取上一个页面传过来的参数
    getParams() {
      this.typeId = this.$route.params.id;
      this.firstId = this.$route.params.id;
      this.typeList.map((item, index) => {
        if (item.id == this.firstId) {
          this.childTypeList = item.children;
          this.typeList[index].selected = true;
        } else {
          this.typeList[index].selected = false;
        }
      });
    },
    // 获取分类
    async getTypeList() {
      let city_code = this.areaInfo.area[0].slice(0, -2);
      let res = await Product.getTypeList({ city_code });
      if (res.code != 200) return;
      this.typeList = [...this.typeList, ...res.data.types_list];
      this.typeList.map((item, index) => {
        if (item.id == this.firstId) {
          this.childTypeList = item.children;
          this.typeList[index].selected = true;
        } else {
          this.typeList[index].selected = false;
        }
      });
    },
    // 初始化
    loadData() {
      this.loading = true;
      let city_code = this.areaInfo.area[0].slice(0, -2);
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        sort: this.sort,
        type: this.typeId,
        city_code,
        title: this.keywords,
      };
      Product.getProductList(params).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.productList = res.data.list.data;
          this.totalCount = res.data.list.totalCount;
        }
        document.documentElement.scrollTop = 0;
      });
    },
    // 翻页
    handleCurrentChange(e) {
      this.page = e;
      this.loadData();
    },
    // 展开分类
    openMore(type) {
      if (type == 1) {
        this.opened = !this.opened;
      }
      if (type == 2) {
        this.childOpened = !this.childOpened;
      }
    },
    // 一级分类点击
    typeClick(row, current) {
      let childAll = {
        id: "",
        title: "全部分类",
        icon: "https://file.vipcs.com/qslwebsite/all_type_icon.png",
      };
      if (current == 0) {
        this.childTypeList = [...row.children];
      } else {
        this.childTypeList = [childAll, ...row.children];
      }
      this.page = 1;
      this.typeList.map((item, index) => {
        if (item.id == row.id) {
          this.typeList[index].selected = true;
        } else {
          this.typeList[index].selected = false;
        }
      });
      this.childTypeList.map((item, index) => {
        this.childTypeList[index].selected = false;
      });
      this.typeId = row.id;
      this.selectedIndex = current;
      this.sort = undefined;
      this.loadData();
    },
    // 二级分类点击
    childTypeClick(row, index) {
      this.page = 1;
      this.childTypeList.map((item, index) => {
        if (item.id == row.id) {
          this.childTypeList[index].selected = true;
        } else {
          this.childTypeList[index].selected = false;
        }
      });
      if (row.id) {
        this.typeId = row.id;
      } else {
        this.typeId = this.typeList[this.selectedIndex].id;
      }
      this.sort = undefined;
      this.loadData();
    },
    // 排序点击
    sortClick(row) {
      this.page = 1;
      this.sort = row;
      this.loadData();
    },
    // 商品详情
    toDetail(row) {
      this.$router.push({
        path: "goodsDetail",
        query: { id: row.id },
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.contain {
  background: #fff;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  .header {
    width: 100%;
    height: 44px;
    background: #f4f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    .header_title {
      font-size: 18px;
      transition: all 100ms linear;
      &:hover {
        color: #0042cb !important;
      }
    }
    .crumbs {
      width: 1300px;
      margin: 0 auto;
    }
  }
  .search {
    width: 1300px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px auto 20px;
    position: relative;
    .reset {
      position: absolute;
      right: 190px;
      font-size: 21px;
      color: #999;
      cursor: pointer;
      &:hover {
        color: #0042cb;
      }
    }
    .searchInput {
      width: 1124px;
      height: 60px !important;
      box-sizing: border-box;
      border: 1px solid #0042cb;
      padding: 0 20px;
      font-size: 19px;
      &:focus {
        outline: none;
      }
    }
    .submit {
      width: 166px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #0042cb;
      border-radius: 0;
      color: #fff;
      box-sizing: border-box;
      &:hover {
        color: #0042cb;
        border: 1px solid #0042cb;
        background: #fff;
        cursor: pointer;
      }
    }
  }
  .classify {
    width: 1300px;
    margin: 0 auto;
    .row {
      height: 66px;
      line-height: 66px;
      width: 100%;
      border-bottom: 1px solid #f4f8ff;
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      padding: 0 72px 20px 132px;
      box-sizing: border-box;
      position: relative;
      transition: all 150ms linear;
      .title {
        position: absolute;
        left: 0;
        margin-top: 20px;
        height: 25px;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        margin-right: 60px;
      }
      .item {
        margin-top: 20px;
        height: 25px;
        font-size: 18px;
        line-height: 25px;
        margin-right: 60px;
        cursor: pointer;
        &:hover {
          color: #0042cb;
        }
      }
      .selected {
        color: #0042cb;
      }
      .open {
        margin-top: 20px;
        cursor: pointer;
        position: absolute;
        right: 0;
        font-size: 18px;
        line-height: 25px;
        user-select: none;
        &:hover {
          color: #0042cb;
        }
      }
    }
    .opened {
      height: auto;
    }
  }

  .goodsList {
    width: 1300px;
    margin: 50px auto 0;
    height: auto;
    .content {
      width: 1320px;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .item {
        float: left;
        display: flex;
        flex-direction: column;
        width: 243px;
        height: 380px;
        padding: 10px;
        margin-bottom: 20px;
        margin-right: 21px;
        box-sizing: border-box;
        border-radius: 4px;
        background: #fafafa;
        cursor: pointer;
        transition: all 100ms linear;
        &:hover {
          box-shadow: 0px 0px 10px 0px #bcc5d3;
        }

        .goods_img {
          width: 223px;
          height: 223px;
          border-radius: 2px;
        }
        .title {
          height: 50px;
          margin-top: 10px;
          width: 223px;
          font-size: 16px;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .consult {
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          margin-top: 5px;
          color: #666;
        }
        .sales {
          margin-top: 20px;
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          color: #666;
        }
      }
      .footer {
        height: 150px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
</style>