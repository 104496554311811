import http from "@/request/http.js";
/**
 * 请求示例
 * @param method 请求方式 get post put delete
 * @param url 请求地址
 * http.method(url,params)
 */
class Product {
  // 首页商品数据初始化
  static getHomeData(params) {
    return http.get('platform/website/homeindex', params)
  }
  // 获取商城首页banner
  static getBannerList(params) {
    return http.get('platform/website/websiteBannerListIndex', params)
  }
  // 全部商品列表
  static getProductList(params) {
    return http.get('platform/website/homelist', params)
  }
  // 商品详情
  static getProductdetail(params) {
    return http.post('platform/website/productdetail', params)
  }
  // 获取商品分类列表
  static getTypeList(params) {
    return http.get('platform/website/webSiteTypeList', params)
  }

}
export default Product
