import home from '@/views/home'
import NotFound from '@/views/NotFound'
import aboutUs from '@/views/aboutUs'
import serviceCase from '@/views/serviceCase'
import cooperation from '@/views/cooperation'
import goodsPrices from '@/views/goodsPrices'
import promotePrices from '@/views/promotePrices'
import cloudGoods from '@/views/cloudGoods'
import goodsList from '@/views/goodsList'
import goodsDetail from '@/views/goodsDetail'
import joinForm from '@/views/joinForm'
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/NotFound',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs
  },
  {
    path: '/serviceCase',
    name: 'serviceCase',
    component: serviceCase
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: cooperation
  },
  {
    path: '/goodsPrices',
    name: 'goodsPrices',
    component: goodsPrices
  },
  {
    path: '/promotePrices',
    name: 'promotePrices',
    component: promotePrices
  },
  {
    path: '/cloudGoods',
    name: 'cloudGoods',
    component: cloudGoods,
  },
  {
    path: '/goodsList',
    name: 'goodsList',
    component: goodsList,
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: goodsDetail,
  },
  {
    path: '/joinForm',
    name: 'joinForm',
    component: joinForm,
  }
]
export default routes