<template>
  <div>
    <div class="formData" v-if="formType == 1">
      <div class="title">企商链推广联盟合伙人申请表</div>
      <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
        <el-form-item label="1.公司全称" prop="company_name">
          <el-input
            :value="form.company_name"
            v-model="form.company_name"
            placeholder="以营业执照为准"
          ></el-input>
        </el-form-item>
        <el-form-item label="2.意向代理区域" prop="agent_area">
          <el-input
            :value="form.agent_area"
            v-model="form.agent_area"
          ></el-input>
        </el-form-item>
        <el-form-item label="3.姓名" prop="name">
          <el-input :value="form.name" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="4.手机号" prop="mobile">
          <el-input
            :maxlength="11"
            :value="form.mobile"
            v-model="form.mobile"
            οninput="value=value.replace(/[^\d]/g,'');if(value.length > 11)value = value.slice(0, 11)"
          ></el-input>
        </el-form-item>
        <el-form-item label="5.邮箱" prop="email">
          <el-input :value="form.email" v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="6.经营范围">
          <el-input
            :value="form.operate_range"
            v-model="form.operate_range"
          ></el-input>
        </el-form-item>
        <el-form-item label="7.销售人员数量">
          <el-input
            type="number"
            :value="form.saler_nums"
            v-model="form.saler_nums"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="submit" @click="submitForm('form')">提交</div>
        </el-form-item>
      </el-form>
    </div>
    <div class="formData" v-if="formType == 2">
      <div class="title">企商链服务商申请表</div>
      <el-form
        :model="serviceForm"
        :rules="serviceFormRules"
        ref="serviceForm"
        class="demo-ruleForm"
      >
        <el-form-item label="1." prop="type">
          <el-radio v-model="serviceForm.type" @change="typeChange(2)" label="2"
            >个人</el-radio
          >
          <el-radio v-model="serviceForm.type" @change="typeChange(3)" label="3"
            >公司</el-radio
          >
        </el-form-item>
        <el-form-item
          label="2.公司名称"
          prop="company_name"
          v-show="serviceForm.type == 3"
        >
          <el-input
            :value="serviceForm.company_name"
            v-model="serviceForm.company_name"
            placeholder="以营业执照为准"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="2 + (serviceForm.type == 3 ? 1 : 0) + '.联系人姓名'"
          prop="name"
        >
          <el-input
            :value="serviceForm.name"
            v-model="serviceForm.name"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="3 + (serviceForm.type == 3 ? 1 : 0) + '.联系人电话'"
          prop="mobile"
        >
          <el-input
            :maxlength="11"
            :value="serviceForm.mobile"
            v-model="serviceForm.mobile"
            οninput="value=value.replace(/[^\d]/g,'');if(value.length > 11)value = value.slice(0, 11)"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="4 + (serviceForm.type == 3 ? 1 : 0) + '.所在城市'"
          prop="agent_area"
        >
          <el-input
            :value="serviceForm.agent_area"
            v-model="serviceForm.agent_area"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="6 + (serviceForm.type == 3 ? 1 : 0) + '.主营业务'"
          prop="operate_range"
        >
          <el-input
            :value="serviceForm.operate_range"
            v-model="serviceForm.operate_range"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="5 + (serviceForm.type == 3 ? 1 : 0) + '.邮箱'"
          prop="email"
        >
          <el-input
            :value="serviceForm.email"
            v-model="serviceForm.email"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="7 + (serviceForm.type == 3 ? 1 : 0) + '.可提供什么服务或产品'"
        >
          <el-input
            :value="serviceForm.service"
            v-model="serviceForm.service"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="submit" @click="submitForm('serviceForm')">提交</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Cooperation from "@/api/cooperation";
export default {
  data() {
    // 邮箱校验规则
    const checkEmail = (rule, value, callback) => {
      let emailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (emailReg.test(value) || value == "") {
        return callback();
      } else {
        callback(new Error("邮箱格式不正确"));
      }
    };
    return {
      form: {
        company_name: "",
        agent_area: "",
        name: "",
        mobile: "",
        email: "",
        operate_range: "",
        saler_nums: "",
      },
      serviceForm: {
        company_name: "",
        agent_area: "",
        name: "",
        mobile: "",
        email: "",
        operate_range: "",
        service: "",
        type: "",
      },
      formType: undefined,
      rules: {
        company_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        agent_area: [
          { required: true, message: "请输入意向代理区域", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, message: "手机号格式不正确", trigger: "blur" },
        ],
        email: [{ required: false, validator: checkEmail, trigger: "blur" }],
      },
      serviceFormRules: {
        type: [
          { required: true, message: "请选择个人或者公司", trigger: "change" },
        ],
        company_name: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        agent_area: [
          { required: true, message: "请输入所在城市", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, message: "手机号格式不正确", trigger: "blur" },
        ],
        operate_range: [
          { required: true, message: "请输入主营业务", trigger: "blur" },
        ],
        email: [{ required: false, validator: checkEmail, trigger: "blur" }],
      },
    };
  },
  created() {
    this.getParams();
  },
  mounted() {},
  methods: {
    // 获取参数
    getParams() {
      this.formType = this.$route.query.type;
    },
    // 提交表单
    submitForm(formName) {
      console.log(this.serviceForm.type);
      if (this.serviceForm.type == 2) {
        delete this.serviceFormRules["company_name"];
      } else {
        this.serviceFormRules["company_name"] = [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ];
      }
      console.log(this.serviceFormRules["company_name"]);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formType == 1) {
            this.addJoinAsk();
          } else {
            this.addJoinService();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提交加盟
    addJoinAsk() {
      if (!this.verifyPhone(this.form.mobile)) return;
      Cooperation.addJoinAsk(this.form).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "提交成功！请等待客服联系~",
            type: "success",
          });
          this.resetForm();
          setTimeout(() => {
            this.$router.push("cooperation");
          }, 2000);
          return;
        }
        this.$message({
          message: "表单数据格式填写错误，请重新填写",
          type: "error",
        });
      });
    },
    // 提交服务商申请
    addJoinService() {
      if (!this.verifyPhone(this.serviceForm.mobile)) return;
      Cooperation.addJoinService(this.serviceForm).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "提交成功！请等待客服联系~",
            type: "success",
          });
          this.resetForm();
          setTimeout(() => {
            this.$router.push("/");
          }, 2000);
          return;
        }
        this.$message({
          message: "表单数据格式填写错误，请重新填写",
          type: "error",
        });
      });
    },
    // 单选改变
    typeChange(type) {
      this.$refs["serviceForm"].resetFields();
      this.serviceForm["type"] = type.toString();
    },
    // 重置表单
    resetForm() {
      if (this.formType == 1) {
        this.$refs["form"].resetFields();
      } else {
        this.$refs["serviceForm"].resetFields();
      }
    },
    // 验证手机号
    verifyPhone(value) {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
        this.$message({
          message: "输入的电话号码有误，请重新填写",
          type: "error",
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang='scss' scoped>
.formData {
  margin: 50px auto;
  padding: 0 75px;
  width: 750px;
  height: 900px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px #f1f5ff;
  border-radius: 9px;
  border: 2px solid #f3f8ff;
  box-sizing: border-box;
  .title {
    margin: 50px auto;
    text-align: center;
    height: 25px;
    font-size: 22px;
    line-height: 25px;
  }
  .submit {
    width: 600px;
    height: 40px;
    background: #2f72fe;
    font-size: 14px;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>