<template>
  <div>
    <div class="contain">
      <div class="banner">
        <img
          v-real-img="baseUrl + 'promotePrices.png'"
          src="../../assets/default_404.svg"
          alt=""
        />
      </div>
      <div class="center">
        <img
          v-real-img="baseUrl + 'promotePrices_banner.png'"
          src="../../assets/default_404.svg"
          alt=""
        />
      </div>
      <div class="table_box">
        <div class="table_header">
          <div>权益</div>
          <div>
            5万产品包<br />
            <span style="color: #191919; font-weight: normal"
              >（总价值: 26.11万）</span
            >
          </div>
          <div>
            8万产品包<br />
            <span style="color: #191919; font-weight: normal"
              >（总价值: 52.22万）</span
            >
          </div>
        </div>
        <el-table :data="tableList" style="width: 100%" :show-header="false">
          <el-table-column width="400" align="center">
            <template #default="{ row }">
              <div class="item_box">
                <div class="tatble_title">{{ row.title }}</div>
                <div class="tatble_title">{{ row.intro }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="400" align="center">
            <template #default="{ row }">
              <div class="item_box">
                <div class="number" v-html="row.num_1"></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="400" align="center">
            <template #default="{ row }">
              <div class="item_box">
                <div class="number" v-html="row.num_2"></div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">一次会销即可能回本创收 全国抢位快来咨询吧</div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
export default {
  data() {
    return {
      baseUrl: config.baseUrl,
      tableList: [
        {
          title: "企商链会员（1年）",
          intro: "",
          num_1: "15个<br />（11800x15=177000元）",
          num_2: "30个<br />（11800 x30=354000元）",
        },
        {
          title: "代账圈会员（3年）",
          intro: "",
          num_1: "50个<br />（588x50=29400元）",
          num_2: "100个<br />（588 x 100=58800元）",
        },
        {
          title: "《代账企业利润增长训练营》",
          intro: "线下培训名额",
          num_1: "10个<br />（2980x10=29800元）",
          num_2: "20个<br />（2980x20=59600元）",
        },
        {
          title: "《营销+管理赋能方案》",
          intro: "+《会计进阶红宝书》",
          num_1: "50套<br />（498 x50=24900元）",
          num_2: "100套<br />（498 x100=49800元）",
        },
        {
          title: "首提产品包销售完成后",
          intro: "续费合作价",
          num_1: "4折/单",
          num_2: "3.5折/单",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.contain {
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .center {
    margin-top: 60px;
    width: 1200px;
    img {
      width: 1200px;
      height: auto;
    }
  }
  .table_box {
    width: 1200px;
    box-shadow: 0px 0px 15px 6px rgba(226, 238, 255, 0.5);
    border-radius: 28px;
    border: 2px solid #0042cb;
    margin: 60px 0 60px;
    overflow: hidden;
    padding-bottom: 25px;
    .item_box {
      height: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .tatble_title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
        color: #191919;
      }
      .number {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        color: #666;
      }
    }
    .table_header {
      height: 130px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f5f7fa;
      div {
        flex: 1;
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #0042cb;
        line-height: 46px;
      }
    }
  }
  .el-table::v-deep {
    border: 0;
    th,
    tr,
    td {
      border: 0;
      background-color: #fff;
    }
    &::before {
      height: 0px;
    }
    &::after {
      width: 0;
    }
    .el-table__fixed:before {
      height: 0;
    }
  }
  .el-table::v-deep th.is-leaf {
    /* 去除上边框 */
    border: none;
  }
  .footer {
    width: 1104px;
    height: 111px;
    background: #0042cb;
    font-size: 48px;
    font-weight: 600;
    color: #ffffff;
    line-height: 111px;
    text-align: center;
    transform: skew(-15deg);
    margin-bottom: 60px;
  }
}
</style>