<template>
  <div>
    <div class="contain">
      <div class="banner">
        <img
          v-real-img="baseUrl + 'qudao_banner.png'"
          src="../../assets/default_404.svg"
          alt=""
        />
      </div>
      <div class="slogen">
        <div class="title">携手企商链，共同驶向新蓝海</div>
        <div class="intro">
          <span class="blue">2500亿</span
          >流量&nbsp;&nbsp;&nbsp;&nbsp;静待赋能&nbsp;&nbsp;&nbsp;&nbsp;
          <span class="blue">10万+</span
          >财税代账企业&nbsp;&nbsp;&nbsp;&nbsp;亟待转型
        </div>
      </div>
      <div class="provider">
        <div class="title">如何成为推广联盟伙伴</div>
        <div class="top">
          <div class="icon">
            <img
              v-real-img="baseUrl + 'kefu.png'"
              src="../../assets/default_404.svg"
              alt=""
            />
            <div class="text">客服咨询</div>
          </div>
          <div class="icon">
            <img
              v-real-img="baseUrl + 'edit.png'"
              src="../../assets/default_404.svg"
              alt=""
            />
            <div class="text">级别申请</div>
          </div>
          <div class="icon">
            <img
              v-real-img="baseUrl + 'notebook.png'"
              src="../../assets/default_404.svg"
              alt=""
            />
            <div class="text">渠道/代理认证</div>
          </div>
          <div class="icon">
            <img
              v-real-img="baseUrl + 'tree.png'"
              src="../../assets/default_404.svg"
              alt=""
            />
            <div class="text">业务开展</div>
          </div>
          <div class="icon">
            <img
              v-real-img="baseUrl + 'forms.png'"
              src="../../assets/default_404.svg"
              alt=""
            />
            <div class="text">市场指导</div>
          </div>
        </div>
        <div class="button" @click="joinUs(1)">合作咨询</div>
      </div>
      <div class="provider service">
        <div class="title">如何成为服务商</div>
        <div class="top">
          <div class="icon">
            <img
              v-real-img="baseUrl + 'kefu_blue.png'"
              src="../../assets/default_404.svg"
              alt=""
            />
            <div class="text">咨询</div>
          </div>
          <div class="icon">
            <img
              v-real-img="baseUrl + 'edit_blue.png'"
              src="../../assets/default_404.svg"
              alt=""
            />
            <div class="text">申请</div>
          </div>
          <div class="icon">
            <img
              v-real-img="baseUrl + 'zoom_blue.png'"
              src="../../assets/default_404.svg"
              alt=""
            />
            <div class="text">审核</div>
          </div>
          <div class="icon">
            <img
              v-real-img="baseUrl + 'enter_blue.png'"
              src="../../assets/default_404.svg"
              alt=""
            />
            <div class="text">开通</div>
          </div>
        </div>
        <div class="button" @click="joinUs(2)">合作咨询</div>
      </div>
      <div class="content">
        <div class="content_title">一站式科技孵化，切实满足服务商需求</div>
        <div class="top_box">
          <div
            v-for="(item, index) in serviceList"
            :key="index"
            :class="['item', 'border' + index]"
          >
            <div :class="['name', 'color' + index]">{{ item.title }}</div>
            <div class="intro" v-html="item.intro"></div>
          </div>
        </div>
        <div style="background: #f4f8ff">
          <div class="content_title">强大的会议辅助销售能力</div>
          <div class="center_box">
            <div class="item" v-for="(item, index) in centerList" :key="index">
              <div class="icon">
                <img
                  v-real-img="baseUrl + item.image"
                  src="../../assets/default_404.svg"
                  alt=""
                />
              </div>
              <div class="intro" v-html="item.intro"></div>
            </div>
          </div>
        </div>
        <div class="content_title">第一届中国代博会</div>
        <div class="img_box">
          <div class="img" v-for="(item, index) in firstStepList" :key="index">
            <img
              v-real-img="baseUrl + item"
              src="../../assets/default_404.svg"
              alt=""
            />
          </div>
        </div>
        <div class="content_title">连续参展三届中国财博会</div>
        <div class="img_box">
          <div class="img" v-for="(item, index) in thirdStepList" :key="index">
            <img
              v-real-img="baseUrl + item"
              src="../../assets/default_404.svg"
              alt=""
            />
          </div>
        </div>
        <div class="content_title">区域代账行业协会会议组织赋能</div>
        <div class="img_box">
          <div class="img" v-for="(item, index) in hunanList" :key="index">
            <img
              v-real-img="baseUrl + item"
              src="../../assets/default_404.svg"
              alt=""
            />
          </div>
        </div>
        <!-- <div class="footer_img">
          <img
            v-real-img="baseUrl + 'qudao_footer.png'"
            src="../../assets/default_404.svg"
            alt=""
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
export default {
  data() {
    return {
      baseUrl: config.baseUrl,
      serviceList: [
        {
          title: "技术服务",
          intro:
            "所有的产品更新迭代<br />以及功能的开发<br />都由企商链总部负责",
        },
        {
          title: "培训服务",
          intro:
            "企商链专业培训体系<br />店铺培训、产品培训<br />均由企商链总部负责",
        },
        {
          title: "运营服务",
          intro:
            "企商链专业运营团队从产品<br />运营、活动运营、用户运营<br />全方位运营指导助服务商发展壮大",
        },
        {
          title: "品牌服务",
          intro:
            "企商链每年都会<br />对优秀服务商进行品牌推广和宣传<br />塑造服务商品牌知名度",
        },
        {
          title: "售后服务",
          intro:
            "企商链有强大的售后团队和体系<br />服务商做好渠道及销售<br />总部负责所有售后服务",
        },
      ],
      centerList: [
        {
          image: "drawing_1.png",
          intro: "深度行业洞察<br />针对性解决方案落地性强",
        },
        {
          image: "drawing_2.png",
          intro: "全国运营<br />成功经验快速复制能力",
        },
        {
          image: "drawing_3.png",
          intro: "完整运营体系<br />陪跑服务无忧",
        },
      ],
      firstStepList: [
        "session-1-1.png",
        "session-1-2.png",
        "session-1-3.png",
        "session-1-4.png",
        "session-1-5.png",
        "session-1-6.png",
      ],
      thirdStepList: [
        "session-3-1.png",
        "session-3-2.png",
        "session-3-3.png",
        "session-3-4.png",
        "session-3-5.png",
        "session-3-6.png",
      ],
      hunanList: [
        "huan-1.png",
        "huan-2.png",
        "huan-3.png",
        "huan-4.png",
        "huan-5.png",
        "huan-6.png",
        "huan-7.png",
        "huan-8.png",
        "huan-9.png",
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    joinUs(type) {
      this.$router.push("joinForm?type=" + type);
    },
  },
};
</script>

<style lang='scss' scoped>
.contain {
  background: #fff;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  .banner {
    img {
      width: 100%;
      height: auto;
    }
  }
  .slogen {
    height: 203px;
    width: 100%;
    .title {
      height: 45px;
      font-size: 32px;
      font-weight: 400;
      color: #191919;
      line-height: 45px;
      margin-top: 60px;
      text-align: center;
    }
    .intro {
      margin-top: 10px;
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      color: #191919;
      line-height: 28px;
      letter-spacing: 3px;
      text-align: center;
      .blue {
        font-size: 22px;
        font-weight: 600;
        color: #0042cb;
        line-height: 28px;
      }
    }
  }

  .provider {
    width: 100%;
    height: 543px;
    box-sizing: border-box;
    background-color: #f4f8ff;
    overflow: hidden;
    .title {
      margin: 60px auto 0;
      text-align: center;
      height: 45px;
      font-size: 32px;
      line-height: 45px;
    }
    .top {
      width: 1300px;
      margin: 40px auto 0;
      height: 147px;
      border-bottom: #ccdeff 1px dashed;
      display: flex;
      // padding: 0 310px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .icon {
        position: relative;
        width: 120px;
        height: 100%;
        img {
          width: 120px;
          height: 120px;
        }
        &::after {
          content: "";
          width: 12px;
          height: 12px;
          background: #b7c7e2;
          border-radius: 50%;
          position: absolute;
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%);
        }
        .text {
          width: 200px;
          height: 22px;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          position: absolute;
          bottom: -47px;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
        }
      }
    }

    .button {
      margin: 106px auto 0;
      width: 355px;
      height: 74px;
      background: #2f72fe;
      border-radius: 37px;
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 74px;
      cursor: pointer;
      border: none;
      transition: all 200ms ease;
      &:hover {
        background: #fff;
        border: 1px solid #2f72fe;
        color: #2f72fe;
      }
    }
  }
  .service {
    background-color: #2f72fe;
    .title {
      color: #fff;
    }
    .top {
      border-bottom: #fff 1px dashed;
      .icon {
        .text {
          color: #fff;
        }
      }
    }
    .button {
      background: #fff;
      color: #191919;
      font-weight: 600;
      &:hover {
        background: #2f72fe;
        border: 1px solid #fff;
        color: #fff;
      }
    }
  }
  .content {
    width: 100%;
    padding-bottom: 40px;
    .content_title {
      // margin: 40px auto 40px;
      padding: 40px;
      text-align: center;
      height: 45px;
      font-size: 32px;
      line-height: 45px;
    }
    .top_box {
      margin: 0 auto 60px;
      width: 1300px;
      height: 250px;
      display: flex;
      justify-content: space-between;
      .item {
        width: 248px;
        height: 230px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px #e3ecfb;
        overflow: hidden;
        border-bottom: 7px solid #fff;
        box-sizing: border-box;
        transition: all 300ms ease;
        &:hover {
          transform: translateY(-20px);
          box-shadow: 0px 0px 20px 0px #c0cdee;
        }
        .name {
          color: #ff662b;
          height: 45px;
          font-size: 32px;
          font-weight: 400;
          line-height: 45px;
          margin-top: 35px;
          text-align: center;
        }
        .color0 {
          color: #ff662b;
        }
        .color1 {
          color: #f43662;
        }
        .color2 {
          color: #2777ff;
        }
        .color3 {
          color: #39da8c;
        }
        .color4 {
          color: #ff8735;
        }
        .intro {
          margin-top: 20px;
          text-align: center;
          height: 87px;
          font-size: 15px;
          font-weight: 400;
          line-height: 29px;
        }
      }
      .border0 {
        border-image: linear-gradient(90deg, #ff8a40 0%, #ff380f 100%) 7;
      }
      .border1 {
        border-image: linear-gradient(90deg, #ec2d2f 0%, #fd3f9a 100%) 7;
      }
      .border2 {
        border-image: linear-gradient(135deg, #233aff 0%, #2cc3ff 100%) 7;
      }
      .border3 {
        border-image: linear-gradient(135deg, #31e4e1 0%, #39d987 100%) 7;
      }
      .border4 {
        border-image: linear-gradient(135deg, #ff7430 0%, #ffc145 100%) 7;
      }
    }
    .center_box {
      margin: 0 auto 20px;
      padding: 20px;
      width: 1260px;
      height: 310px;
      display: flex;
      justify-content: space-between;
      .item {
        width: 363px;
        height: 280px;
        .icon {
          width: 363px;
          height: 190px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .intro {
          margin-top: 40px;
          font-size: 22px;
          font-weight: 400;
          line-height: 30px;
          text-align: center;
        }
      }
    }
    .img_box {
      margin: 0 auto;
      width: 1300px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .img {
        margin-bottom: 16px;
      }
    }
    .footer_img {
      width: 1300px;
      margin: 40px auto;
    }
  }
  img {
    height: auto;
  }
}
</style>