<template>
  <div>
    <div class="contain">
      <div class="banner">
        <img
          v-real-img="baseUrl + 'fuwu_banner.png'"
          src="../../assets/default_404.svg"
          alt=""
        />
      </div>
      <div class="top_box">
        <img
          v-real-img="baseUrl + 'anli_top.png'"
          src="../../assets/default_404.svg"
          alt=""
        />
      </div>
      <div class="center_title">牵手企商链 下一个成功案例就是你</div>
      <div class="center_box">
        <div class="item" v-for="(item, index) in caseList" :key="index">
          <div class="image">
            <img
              v-real-img="baseUrl + item.image"
              src="../../assets/default_404.svg"
              alt=""
            />
          </div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
      <div class="bottom_box">
        <div class="substance">
          <div class="left">
            <div
              class="item"
              v-for="(item, index) in matterList"
              :key="index"
              @mouseenter="mouseenter(index)"
            >
              <img
                v-real-img="baseUrl + item.image"
                src="../../assets/default_404.svg"
                alt=""
              />
              <div class="mask" v-if="currentIndex != index">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="right_box">
            <transition name="el-fade-in">
              <div
                class="right"
                v-for="(item, index) in matterList"
                :key="index"
                v-if="currentIndex == index"
              >
                <div class="title">{{ item.title }}</div>
                <div class="city">
                  案例区域：<span style="color: #0042cb">{{ item.city }}</span>
                </div>
                <div class="synopsis">{{ item.synopsis }}</div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="footer">
        <img
          v-real-img="baseUrl + 'anli_foot.png'"
          src="../../assets/default_404.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
export default {
  name: "serviceCase",
  data() {
    return {
      baseUrl: config.baseUrl,
      caseList: [
        {
          image: "anli_1.png",
          content:
            "北京华财会计股份有限公司，作为行业龙头，有着众多分公司和联盟单位，分工明确，组织有序，在选择合作伙伴上非常审慎。企商链平台在精准获客以及足迹分析方面，相较于其之前考察过的平台有着较为明显的优势。因此，双方联手，以期共同进步。",
        },
        {
          image: "anli_2.png",
          content:
            "邦达（辽宁）企业服务有限公司，在财税业务的基础上，因着老板的个人魅力，吸引了高端资源，组成了商学院。核心诉求为：在企商链平台上，除了做好自营财税业务的经营和云仓产业增收外，开辟一个商学院专栏，服务商学院活动。在运营部和客户成功部的努力下，商学院板块已搭建成功，并且推荐联盟商家进行资源展示和撮合，获得了邦达的认可。",
        },
        {
          image: "anli_3.png",
          content:
            "大连卓勤财税管理集团有限公司，立足辽宁、辐射东北、服务全国，以高端财税服务业务为主导，服务各类企业数千家，全方位助力企业及个人财税顺通。近些年，企商链的产品实力和服务能力吸引了卓勤财税，希望能更好地赋能高端产品落地，将客户分层、分级，制定针对性价值挖掘策略，打造粘性长链增长关系。未来，双方将通力合作，一起赋能财税市场，用会员制留存客户，跑赢多元化增收之路。",
        },
        {
          image: "anli_4.png",
          content:
            "内蒙立业会计服务有限公司，是内蒙代账协会会长单位。公司创始人清晰的知道，当下竞争日趋白热化的市场环境下，高质量服务客户以及全员营销异常重要。作为一款财税公司全员营销+产业增收的全解决方案平台，进入了创始人的视线。目前，内蒙立业已配置完成店铺，即将迎来量身定制的陪跑服务。",
        },
      ],
      matterList: [
        {
          title: "私域会员增长",
          city: "济南",
          image: "zengzhang_1.png",
          synopsis:
            "案例简介：通过企商链运营指导，联盟商家观影回馈，展示自有企商链企服云店会员数量及联盟商家服务资源曝光对接板块、会员权益展示板块，吸引影城长期合作意愿，实现异业联盟双向引流核销，促进其私域会员增长。",
        },
        {
          title: "获客拉新增长",
          city: "苏州",
          image: "zengzhang_2.png",
          synopsis:
            "案例简介：企商链运营团队根据对方的会员拉新诉求，帮助代账公司挑选促销品及营销工具包，并设置每日全员分项指标，通过企商链活动链接组织全员营销分发，实现活动增收。",
        },
        {
          title: "活动激活增长",
          city: "河北",
          image: "zengzhang_3.png",
          synopsis:
            "案例简介：企商链运营团队辅导代账机构盘点客户情况，运用企商链增收工具形成链接，举办6·1送好礼活动，和当地的联盟商家互利互惠，通过线上活动最大程度带动增长，促进续费转介绍实现增收。",
        },
        {
          title: "云仓产品增长",
          city: "郑州",
          image: "zengzhang_4.png",
          synopsis:
            "案例简介：通过企商链定制活动方案设计，将活动优惠生动有效触达至客户，通过分享企商链商城活动裂变码，达到续费即时付款、增收翻倍的目的，助力其完成目标成交金额。",
        },
      ],
      currentIndex: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 鼠标移入
    mouseenter(key) {
      this.currentIndex = key;
    },
    // 鼠标移出
    mouseleave(key) {},
  },
};
</script>

<style lang='scss' scoped>
.contain {
  background: #fff;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  .banner {
    img {
      width: 100%;
      height: auto;
    }
  }
  .top_box {
    width: 1300px;
    margin: 60px auto 0;
    img {
      width: 1300px;
    }
  }
  .center_title {
    height: 81px;
    font-size: 58px;
    font-weight: 600;
    color: #fe2f4d;
    line-height: 81px;
    margin: 60px 0;
    text-align: center;
  }
  .center_box {
    margin: 60px auto 0;
    width: 1275px;
    height: 1494px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: 628px;
      height: 727px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px #e3ecfb;
      image {
        width: 628px;
        height: 365px;
      }
      .content {
        width: 588px;
        margin: 20px auto 0;
        text-align: justify;
        text-indent: 2em;
        color: #191919;
        line-height: 46px;
        font-size: 22px;
      }
    }
  }
  .bottom_box {
    width: 100%;
    height: 1042px;
    background-image: url("https://file.vipcs.com/qslwebsite/anli_bg.png");
    margin: 60px auto 0;
    overflow: hidden;
    .substance {
      margin: 59px auto 0;
      width: 1275px;
      height: 924px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 386px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .item {
          width: 100%;
          height: 216px;
          border-radius: 12px;
          background: #fe2f4d;
          position: relative;
          overflow: hidden;
          .iamge {
            width: 100%;
            height: 216px;
          }
          .mask {
            width: 100%;
            height: 216px;
            line-height: 216px;
            text-align: center;
            font-size: 48px;
            font-weight: 600;
            color: #ffffff;
            background: #000;
            opacity: 0.7;
            position: absolute;
            top: 0;
          }
        }
      }
      .right_box {
        overflow: hidden;
        width: 837px;
        height: 380px;
      }
      .right {
        width: 837px;
        height: 380px;
        background: #ffffff;
        border-radius: 12px;
        padding: 60px 40px 0;
        box-sizing: border-box;
        .title {
          height: 37px;
          font-size: 26px;
          font-weight: 600;
          color: #0042cb;
          line-height: 37px;
          position: relative;
          margin-left: 14px;
          &::after {
            content: "";
            width: 8px;
            height: 28px;
            background: #0042cb;
            position: absolute;
            left: -14px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .city {
          height: 30px;
          font-size: 22px;
          font-weight: 400;
          color: #191919;
          line-height: 30px;
          margin-top: 15px;
        }
        .synopsis {
          margin-top: 30px;
          width: 757px;
          font-size: 22px;
          font-weight: 400;
          color: #191919;
          line-height: 50px;
          text-align: justify;
        }
      }
    }
  }
  .footer {
    width: 1300px;
    margin: 60px auto 0;
    img {
      width: 1300px;
    }
  }
}
</style>